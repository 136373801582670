import styles from "./modalApplicationMobile.module.scss";
import { TextInput } from "../../textInput/textInput";
import { Button } from "../../button/button";
import { CommonModalGreenMobile } from "../commonModalGreenMobile/commonModalGreenMobile";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_APPLICATION_SCHEMA, CITIES, useForm, } from "../../../utils/applications";
import { useGetRequest, useRequest } from "../../../utils/api";
import { useContext, useEffect, useState } from "react";
import { CalculatorContext } from "../../../pages/template/template";
import {
  extractProgramTitles,
  findPrograms,
  findTitleByType,
} from "../modalApplicationProgram/modalApplicationProgram";
import { CityInput } from "../../cityInput/cityInput";

export const ModalApplicationMobile = ( {
                                          onClose,
                                          addCalculation = false,
                                        }: {
  onClose?: () => void;
  addCalculation?: boolean;
} ) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [ disableButton, setDisableButton ] = useState<boolean>( false );

  const [ buttonTitle, setButtonTitle ] = useState<string>( "Отправить" );

  const { data, errors, setters, isValidationPassed } = useForm(
    BASE_APPLICATION_SCHEMA
  );

  const { applyRequest, response, error } = useRequest();

  const { GetRequestData, getRequest } = useGetRequest();

  useEffect( () => getRequest( "main" ), [] );

  const mainPageData = GetRequestData;

  useEffect( () => {
    if ( response?.status === 200 ) {
      setButtonTitle( "Отправлено!" );
      setDisableButton( true );
    } else {
      setButtonTitle( "Отправить" );
      setDisableButton( false );
    }
  }, [ response, error ] );

  const inputError = Object.keys( errors ).length > 0;

  const { calculation } = useContext( CalculatorContext );

  const programType = location.pathname.slice( 9 );

  const programs = findPrograms( mainPageData?.blocks );

  const extractedTitles = extractProgramTitles( programs );

  const foundTitle = findTitleByType( programType, extractedTitles );

  const terms =
    "Нажимая на кнопку «Отправить», я соглашаюсь с условиями обработки моих персональных данных.";

  const onApply = async () => {
    if ( isValidationPassed() ) {
      setDisableButton( true );
      setButtonTitle( "Отправка..." );
      if ( location.pathname === "/" ) {
        if ( addCalculation ) {
          applyRequest(
            {
              name: data.name,
              phone: data.phone,
              inn: data.company,
              city: data.city,
              email: data.email,
              calculation,
            },
            "main"
          );
        } else {
          applyRequest(
            {
              name: data.name,
              phone: data.phone,
              inn: data.company,
              city: data.city,
              email: data.email,
            },
            "main"
          );
        }
      } else if ( location.pathname.indexOf( "program" ) === 1 ) {
        if ( addCalculation ) {
          applyRequest(
            {
              name: data.name,
              phone: data.phone,
              inn: data.company,
              city: data.city,
              email: data.email,
              type: foundTitle,
              calculation,
            },
            "program"
          );
        } else {
          applyRequest(
            {
              name: data.name,
              phone: data.phone,
              inn: data.company,
              city: data.city,
              email: data.email,
              type: foundTitle,
            },
            "program"
          );
        }
      }
    }
  };

  return (
    <CommonModalGreenMobile
      buttonTitle={ "Оставить заявку" }
      title={ "Введите данные" }
      onClose={ onClose }
    >
      <TextInput
        value={ data.name }
        onChange={ setters.name }
        dark
        placeholder={ "Имя*" }
        error={ errors.name }
        type={ "name" }
      />
      <TextInput
        value={ data.phone }
        onChange={ setters.phone }
        dark
        placeholder={ "Телефон*" }
        mask="+7(999)999-99-99"
        error={ errors.phone }
        type={ "phone" }
      />

      <CityInput
        value={ data.city }
        setValue={ setters.city }
        error={ errors.city }
        title={ "Город*" }
        options={ CITIES }
        className={ styles.content }
        style={ "inputField" }
      />
      <TextInput
        value={ data.email }
        onChange={ setters.email }
        dark
        placeholder={ "Электронная почта*" }
        error={ errors.email }
      />
      <TextInput
        value={ data.company }
        onChange={ setters.company }
        dark
        placeholder={ "ИНН организации" }
        type={ "decimals" }
        error={ errors.company }
        maskChar=""
        mask={ "999999999999" }
      />
      <div
        className={ [
          styles.terms,
          styles.terms__input,
          ( inputError && styles.error ) || "",
        ].join( " " ) }
      >
        * — обязательное поле для заполнения
      </div>
      <div
        onClick={ () =>
          navigate( "/privacy-policy", {
            state: { previousLocation: location },
          } )
        }
        className={ styles.terms }
      >
        { terms }
      </div>
      <div className={ styles.buttonContainer }>
        <Button
          title={ buttonTitle }
          size={ "big" }
          className={ styles.button }
          color={ "black" }
          onClick={ onApply }
          disabled={ disableButton }
        />
      </div>
    </CommonModalGreenMobile>
  );
};
