import React from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { useIsMobile } from "../../../../utils/common";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { Button } from "../../../../components/button/button";
import { useCalculatedScale } from "../../../../utils/calcScale";
import { CardItem } from "../loyalty";
import { LazyImage } from "../../../../components/slider/LazyImage";

export type CardProps = {
  title: string;
  image: string;
  to: string;
  totitle: string;
  alone?: boolean;
};
const ServiceCard = (props: CardProps) => {
  const isMobile = useIsMobile();
  if (isMobile) return <CardItem {...props} />;

  return (
    <div
      className={[
        styles.serviceCardContainer,
        (props.alone && styles.serviceCardContainer__alone) || "",
      ].join(" ")}
    >
      <div className={styles.left}>
        <span className={styles.title}>{props.title}</span>
        <Button to={props.to} title={props.totitle} className={styles.button} />
      </div>
      <LazyImage image={props.image} className={styles.image} />
    </div>
  );
};

interface ClientsPageServicesBlockType {
  services: CardProps[];
}

export const ClientsPageServicesBlock = ({
  titleShown,
  blocksShown,
  mobile = false,
  content,
}: DesktopBlockPropsType & { content: ClientsPageServicesBlockType }) => {
  const scale = useCalculatedScale(() => {
    const { clientWidth } = document.documentElement;
    if (clientWidth >= 1440) return 1200;
    if (clientWidth >= 1280) return 1300;
    return 1150;
  });

  return (
    <CommonBlock>
      <div
        className={styles.container}
        style={{
          transform: `scale(${scale}) translateX(${(scale - 1) * 40}%)`,
        }}
      >
        {content.services.map((it, index) => (
          <AnimatedBlock
            className={styles.item}
            isShown={mobile || blocksShown}
            latency={index * 300}
          >
            <ServiceCard {...it} alone={content.services.length < 2} />
          </AnimatedBlock>
        ))}
      </div>
    </CommonBlock>
  );
};
