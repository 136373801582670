import styles from "./discountsContainer.module.scss";
import { ServiceCard } from "../serviceCard/serviceCard";
import { forwardRef, LegacyRef } from "react";
import { useCalculatedScale } from "../../utils/calcScale";
import { DiscountCard } from "../discountCard/discountCard";

export const DiscountsContainer = forwardRef<
  HTMLDivElement,
  {
    services: Array<any>;
    className?: string;
  }
>(({ services, className }, ref) => {
  const scale = useCalculatedScale(() => {
    const cardWidth = 251;
    // TODO: Add proper support for services.length
    return cardWidth * 4 + 24 * 3;
  });

  return (
    <div ref={ref} className={styles.cardContainer}>
      {services.map((it) => {
        return (
          <DiscountCard
            {...it}
            className={
              services.length < 4
                ? services.length < 3
                  ? styles.card__single
                  : styles.card__triple
                : ""
            }
          />
        );
      })}
    </div>
  );
});
