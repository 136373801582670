import React from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { Calculator } from "../../../uiKit/calculator/calculator";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { CalculatorContent } from "../../../program/types/blockTypes";

export const MainPageCalculatorBlock = ({
  onApplicationButtonPress,
  blocksShown,
  mobile,
  content,
  onInfoMobilePress,
}: DesktopBlockPropsType & { content: CalculatorContent } & {
  onInfoMobilePress?: (modalName: string) => void;
}) => {
  return (
    <CommonBlock>
      <AnimatedBlock className={styles.wrapper} isShown={mobile || blocksShown}>
        <Calculator
          title={content.title}
          onApplicationButtonPress={onApplicationButtonPress}
          onInfoMobilePress={onInfoMobilePress}
          content={content}
        />
      </AnimatedBlock>
    </CommonBlock>
  );
};
