import styles from "./modalPrivacyPolicyMobile.module.scss";
import { CommonModalGreenMobile } from "../commonModalGreenMobile/commonModalGreenMobile";
import { CollapsibleParagraph } from "../../collapsibleParagraph/collapsibleParagraph";
import { ModalPrivacyPolicyType } from "../modalPrivacyPolicy/modalPrivacyPolicy";

export const ModalPrivacyPolicyMobile = ({
  onClose,
  content,
}: {
  onClose?: () => void;
  content: ModalPrivacyPolicyType;
}) => {
  return (
    <CommonModalGreenMobile
      buttonTitle={"Политика"}
      title={content.title}
      onClose={onClose}
    >
      <div className={styles.text}>{content.description}</div>
      {content.terms.map((it) => (
        <CollapsibleParagraph title={it.title} children={it.description} />
      ))}
    </CommonModalGreenMobile>
  );
};
