import React, { LegacyRef, useEffect, useState } from "react";
import { CardWithImage } from "../cardWithImage/cardWithImage";
import styles from "./featuresContainer.module.scss";
import { useCalculatedScale } from "../../utils/calcScale";

export const FeaturesContainer = ({
  features,
  ref,
  onScroll,
  short = false,
  active = false,
}: {
  features: Array<any>;
  ref?: LegacyRef<HTMLDivElement>;
  onScroll?: (a: any) => void;
  short?: boolean;
  active?: boolean;
}) => {
  return (
    <div
      //onScroll={onScroll}
      //ref={ref}
      className={[
        styles.mainContainer,
        features.length > 4 ? styles.mainContainer__scroll : "",
      ].join(" ")}
    >
      {features.map((it, index) => (
        <div
          className={[
            styles.cardWrapper,
            index % 2 === 0 ? styles.cardWrapper_even : "",
          ].join(" ")}
        >
          <CardWithImage
            active={active}
            short={short}
            backgroundImage={it.backgroundimage}
            type={it.type}
            content={it.content[0]}
          />
        </div>
      ))}
    </div>
  );
};

export const SideGradient = ({
  className,
  side = "left",
  hidden = false,
}: {
  className?: string;
  side?: "left" | "right";
  hidden?: boolean;
}) => {
  return (
    <div
      className={[
        styles.sideGradient,
        (side === "left"
          ? styles.sideGradient__left
          : styles.sideGradient__right) || "",
        (hidden && styles.sideGradient__hidden) || "",
        className,
      ].join(" ")}
    />
  );
};
