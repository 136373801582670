import React from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { FeaturesContainer } from "../../../../components/featuresContainer/featuresContainer";
import { CardWithImage, CardWithImageContent, } from "../../../../components/cardWithImage/cardWithImage";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { programNames } from "../../../program/types/programNames";

export interface ProgramType {
  backgroundImage: any[];
  type: programNames;
  content: CardWithImageContent[];
}

interface OptionsPageCombinationsBlockType {
  title: string;
  programs: Array<ProgramType>;
}

export const OptionsPageCombinationsBlock = ( {
                                                mobile,
                                                titleShown,
                                                blocksShown,
                                                content,
                                              }: DesktopBlockPropsType & {
  content: OptionsPageCombinationsBlockType
} ) => {
  return (
    <CommonBlock fullWidth>
      <div className={ styles.container }>
        <AnimatedBlock isShown={ mobile || titleShown } className={ styles.title } type='h2'>
          { content.title }
        </AnimatedBlock>

        <AnimatedBlock
          isShown={ mobile || blocksShown }
          className={ styles.programWrapper }
        >
          <FeaturesContainer active short features={ content.programs }/>
        </AnimatedBlock>

        <div className={ styles.programWrapper_mobile }>
          { content.programs.map( ( it ) => (
            <CardWithImage
              active
              short
              backgroundImage={ it.backgroundImage }
              type={ it.type }
              content={ it.content[ 0 ] }
            />
          ) ) }
        </div>
      </div>
    </CommonBlock>
  );
};
