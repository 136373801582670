import React, { ReactNode } from "react";
import styles from "./commonBlock.module.scss";

export const CommonBlock = (
  {
    children,
    className,
    fullWidth = false,
    alignTop = false,
    style,
  }: {
    children?: ReactNode;
    className?: string;
    fullWidth?: boolean;
    alignTop?: boolean;
    style?: any;
  } ) => {
  return (
    <div
      style={ style }
      className={ [ styles.block, fullWidth ? styles.block__fullWidth : "", alignTop ? styles.block__alighTop : "" ].join(
        " "
      ) }
    >
      { children }
    </div>
  );
};
