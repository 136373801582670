import React, { useState } from "react";
import styles from "./appeal.module.scss";
import { CommonModalMobileWrapper } from "../../components/commonModalMobileWrapper/commonModalMobileWrapper";
import { useIsMobile } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { ModalApplicationPartnership } from "../../components/modals/modalApplicationPartnership/modalApplicationPartnership";
import { ModalApplicationPartnershipMobile } from "../../components/modals/modalApplicationPartnershipMobile/modalApplicationPartnershipMobile";

export const PartnershipPage = () => {
  const isMobile = useIsMobile();
  return isMobile ? <PartnershipMobile /> : <PartnershipDesktop />;
};

export const PartnershipMobile = () => {
  const navigate = useNavigate();
  return (
    <CommonModalMobileWrapper
      onClose={() => {
        navigate(-1);
        document.body.style.overflow = "unset";
      }}
      isOpen
    >
      <ModalApplicationPartnershipMobile
        onClose={() => {
          navigate(-1);
          document.body.style.overflow = "unset";
        }}
      />
    </CommonModalMobileWrapper>
  );
};

export const PartnershipDesktop = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ModalApplicationPartnership
        isOpen
        onClose={() => {
          navigate(-1);
          document.body.style.overflow = "unset";
        }}
      />
    </div>
  );
};
