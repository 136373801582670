import React, { useContext, useEffect, useMemo, useState } from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { ClientsDocumentType, Document } from "../../../../components/document/document";
import { useCalculatedScale } from "../../../../utils/calcScale";
import { DropdownPicker } from "../../../../components/dropdownPicker/dropdownPicker";
import { useSearchParams } from "react-router-dom";
import { CardContext } from "../../../../components/cardTransitionWrapper/cardTransitionWrapper";

interface DocumentPageType {
  title: string;
  documents: ClientsDocumentType[];
}

interface ClientsPageDocumentsBlockType {
  pages: DocumentPageType[];
}

export const ClientsPageDocumentsBlock = (
  {
    titleShown,
    blocksShown,
    mobile = false,
    content,
  }: DesktopBlockPropsType & { content: ClientsPageDocumentsBlockType } ) => {
  const [ params ] = useSearchParams();
  const cardRef = useContext( CardContext );

  const scale = useCalculatedScale( () => {
    const { clientWidth } = document.documentElement;
    if ( clientWidth >= 1440 ) return 1200;
    if ( clientWidth >= 1280 ) return 1300;
    return 1150;
  } );

  const pageTitles = useMemo(
    () => Array.from( content.pages, ( page ) => page.title ),
    [ content ]
  );

  const [ pickerValue, setPickerValue ] = useState( pageTitles[ 0 ] );

  useEffect( () => {
    //@ts-ignore
    cardRef.ref?.current?.updateHeightContainer();
  }, [ pickerValue ] )

  const currentPageIndex = useMemo(
    () => pageTitles.indexOf( pickerValue ),
    [ pickerValue ]
  );

  const pickerOptionsShown = useMemo(
    () => pageTitles.filter( ( it, index ) => index !== currentPageIndex ),
    [ currentPageIndex ]
  );

  const page = useMemo(
    () => content.pages[ currentPageIndex ],
    [ currentPageIndex ]
  );

  useEffect( () => {
    let title: any = params.get( 'title' );
    title = pageTitles.find( t => t.toLowerCase().startsWith( title?.toLowerCase() ) );
    if ( title ) {
      setPickerValue( title );
    }

  }, [ params.get( 'title' ) ] );

  return (
    <CommonBlock alignTop={ true }>
      <div
        className={ styles.container }
        style={
          {
            // transform: `scale(${scale}) translateX(${(scale - 1) * 40}%)`,
          }
        }
      >
        <div className={ styles.dropdownRow }>
          Тип документа{ " " }
          <DropdownPicker
            value={ pickerValue }
            setValue={ setPickerValue }
            options={ pickerOptionsShown }
          />
        </div>

        <div className={ styles.columnContainer }>
          { page.documents.map( ( doc, index ) => (
            <AnimatedBlock
              className={ styles.column }
              isShown={ mobile || blocksShown }
              latency={ index * 300 }
            >
              <Document { ...doc } />
            </AnimatedBlock>
          ) ) }
        </div>
      </div>
    </CommonBlock>
  );
};
