import React, { useEffect, useState } from "react";
import styles from "./slider.module.scss";
import { LazyImage } from "./LazyImage";

export interface SliderPageType {
  title: string;
  description: string;
  image: KirbyImage[] | string;
}

export interface KirbyImage {
  image: { url: string; src: string; srcset: string };
}

export const Slider = ({
  pages,
  indicatorOverlay = false,
}: {
  pages: SliderPageType[];
  indicatorOverlay?: boolean;
}) => {
  const [step, setCurrentStep] = useState(1);

  const [animation, setAnimation] = useState(true);

  const currentPage = pages[step - 1] || {};

  const animationDelayMs = 20;

  const setStep = (step: number) => {
    setAnimation(false);
    setCurrentStep(step);
  };

  useEffect(() => {
    if (!animation) {
      setTimeout(() => setAnimation(true), animationDelayMs);
    }
  }, [animation]);

  return (
    <div className={styles.container}>
      <div
        onClick={() => setStep(1)}
        className={[
          styles.slide__piece,
          styles.slide__piece1,
          styles["slide__piece1_step" + step],
        ].join(" ")}
      >
        1
      </div>

      {pages.length > 1 && (
        <div
          onClick={() => setStep(2)}
          className={[
            styles.slide__piece,
            styles.slide__piece2,
            styles["slide__piece2_step" + step],
          ].join(" ")}
        >
          2
        </div>
      )}

      <div className={[styles.slide, styles["slide__number" + step]].join(" ")}>
        <div className={styles.imageContainer}>
          <LazyImage
            className={[
              styles.image,
              (animation && styles.image__active) || "",
            ].join(" ")}
            image={currentPage.image}
            key={step.toString()}
          />
        </div>
        <div className={styles.content}>
          <div
            className={[
              styles.title,
              (animation && styles.title__active) || "",
            ].join(" ")}
          >
            {currentPage.title}
          </div>
          <div
            className={[
              styles.description,
              (animation && styles.description__active) || "",
            ].join(" ")}
          >
            {currentPage.description}
          </div>
          {/* {pages.length > 1 && (
            <div className={styles.progress}>
              {step}/{pages.length}
            </div>
          )} */}
        </div>
      </div>

      {pages.length > 1 && (
        <div
          onClick={() => setStep(2)}
          className={[
            styles.slide__piece,
            styles.slide__piece3,
            styles["slide__piece3_step" + step],
          ].join(" ")}
        >
          2
        </div>
      )}

      {pages.length > 2 && (
        <div
          onClick={() => setStep(3)}
          className={[
            styles.slide__piece,
            styles.slide__piece4,
            styles["slide__piece4_step" + step],
          ].join(" ")}
        >
          3
        </div>
      )}

      <div
        className={[
          styles.progressContainer,
          (indicatorOverlay && styles.progressContainer__overlay) || "",
        ].join(" ")}
      >
        {/*// @ts-ignore*/}
        {[...Array(Math.min(3, pages.length)).keys()].map((_kye, index) => (
          <div
            className={
              styles.progressItem +
              " " +
              (step === index + 1 ? styles.progressItem_active : "")
            }
            onClick={() => setStep(index + 1)}
          />
        ))}
      </div>

      {/*<ArrowButton*/}
      {/*  onClick={() => setStep(step === 1 ? 3 : step - 1)}*/}
      {/*  withBlur*/}
      {/*  className={styles.leftArrow}*/}
      {/*  color="white"*/}
      {/*  direction="left"*/}
      {/*/>*/}

      {/*<ArrowButton*/}
      {/*  onClick={() => setStep(step === 3 ? 1 : step + 1)}*/}
      {/*  withBlur*/}
      {/*  className={styles.rightArrow}*/}
      {/*  color="white"*/}
      {/*  direction="right"*/}
      {/*/>*/}
    </div>
  );
};
