import React, { useRef, useState } from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { ServiceCard } from "../../../../components/serviceCard/serviceCard";
import { ServicesContainer } from "../../../../components/servicesContainer/servicesContainer";
import { ArrowButton } from "../../../../components/arrowButton/arrowButton";
import { SideGradient } from "../../../../components/featuresContainer/featuresContainer";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { useNavigate } from "react-router-dom";

interface OptionCardType {
  type: string;
  icon: string;
  title: string;
  descriptiontop: string;
  description: string;
  ismodal: true;
}

interface ServicesPageOptionsBlockContentType {
  optionsdata: OptionCardType[];
}

export const ServicesPageOptionsBlock = ({
  onProgramSelect,
  blocksShown,
  content,
  mobile,
}: DesktopBlockPropsType & {
  onProgramSelect: (programName: string) => void;
  content: ServicesPageOptionsBlockContentType;
}) => {
  const navigate = useNavigate();

  const options = content.optionsdata.map((_option) => ({
    ..._option,
    onClick: _option.ismodal
      ? () => onProgramSelect(_option.type)
      : () => navigate("/options"),
  }));

  const scrollRef = useRef<HTMLDivElement>(null);

  const [scroll, setScroll] = useState<number>(0);

  const hideButtonsAndBlur = options.length < 5;

  return (
    <CommonBlock fullWidth>
      <div className={styles.screenMobile}>
        {options.map((it, index) => (
          <ServiceCard className={styles.card} {...it} />
        ))}
      </div>
      <div className={styles.screen}>
        <SideGradient
          className={(hideButtonsAndBlur && styles.hide) || ""}
          hidden={scroll < 50}
        />
        <div
          className={[
            styles.arrowsContainer,
            (hideButtonsAndBlur && styles.hide) || "",
          ].join(" ")}
        >
          <ArrowButton
            disabled={scroll === 0}
            className={styles.arrowLeft}
            color={"white"}
          />
          <ArrowButton
            color={"white"}
            direction={"right"}
            onClick={() => scrollRef.current?.scrollTo({ left: 250 })}
          />
        </div>
        <AnimatedBlock
          className={styles.cardWrapper}
          isShown={mobile || blocksShown}
        >
          <ServicesContainer ref={scrollRef} services={options} />
        </AnimatedBlock>
        <SideGradient
          className={(hideButtonsAndBlur && styles.hide) || ""}
          hidden={true}
          side={"right"}
        />
      </div>
    </CommonBlock>
  );
};
