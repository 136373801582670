import styles from "./modalComponentMobile.module.scss";
import { CloseButton } from "../closeButton/closeButton";

export const ModalComponentMobile = ({
  className,
  buttonTitle,
  onButtonClick,
  onClose,
  children,
}: {
  className?: string;
  buttonTitle: string;
  onButtonClick?: () => void;
  onClose?: () => void;
  children?: any;
}) => {
  return (
    <div className={[styles.container, className || ""].join(" ")}>
      <ButtonMobile onClose={onClose} title={buttonTitle} />
      {children}
    </div>
  );
};

export const ButtonMobile = ({
  onClick,
  className,
  title,
  onClose,
}: {
  onClick?: () => void;
  onClose?: () => void;
  className?: string;
  title: string;
}) => {
  return (
    <div
      onClick={onClick}
      className={[styles.button, className || ""].join(" ")}
    >
      <span className={styles.buttonText}>{title}</span>
      <CloseButton square onClick={onClose} className={styles.closeButton} />
    </div>
  );
};
