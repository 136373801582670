import { CSSProperties } from "react";
import styles from "./discountCard.module.scss";
import { Button } from "../button/button";
import { LazyImage } from "../slider/LazyImage";
import { ObjectString } from "../../pages/program/types/blockTypes";

export interface DiscountCardType {
  title: string;
  discount: string;
  description: ObjectString[];
}

export const DiscountCard = ({
  className,
  title,
  discount,
  description,
  titleTextStyle,
  descriptionTextStyle,
}: {
  className?: string;
  titleTextStyle?: CSSProperties;
  descriptionTextStyle?: CSSProperties;
} & DiscountCardType) => {
  return (
    <div className={[styles.mainContainer, className].join(" ")}>
      <div className={styles.topContainer}>{title}</div>
      <div className={styles.contentContainer}>
        <p className={styles.discount} style={titleTextStyle}>
          {discount}
        </p>

        {description.map((it, index) => (
          <p
            className={[
              styles.descriptionText,
              (index === 0 && styles.descriptionText__top) || "",
            ].join(" ")}
            style={descriptionTextStyle}
          >
            {it.value}
          </p>
        ))}
      </div>
    </div>
  );
};
