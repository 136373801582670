import styles from "./modalOptionMobile.module.scss";
import { CommonModalGreenMobile } from "../commonModalGreenMobile/commonModalGreenMobile";
import { ModalOptionType } from "../modalOption/modalOption";
import { Button } from "../../button/button";
import { useNavigate } from "react-router-dom";

export const ModalOptionMobile = ({
  content,
  onClose,
}: {
  content: ModalOptionType | null;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <CommonModalGreenMobile
      buttonTitle={content?.mobileModalCategory || "Опция"}
      title={content?.title}
      onClose={onClose}
    >
      {content?.descriptionheader && (
        <div className={styles.heading}>{content?.descriptionheader}</div>
      )}
      <div
        className={[
          styles.content,
          (!content?.descriptionheader && styles.content__enlarged) || "",
        ].join(" ")}
      >
        {content?.description}
      </div>
      {content?.combineswith && (
        <div className={styles.bottomListContainer}>
          <div className={styles.heading}>
            Сочетается со следующими программами:
          </div>
          <div className={styles.buttonsContainer}>
            {content?.combineswith?.map((it) => (
              <Button
                onClick={() => {
                  navigate("/program/" + it.type);
                  onClose();
                }}
                color={"white"}
                className={styles.button}
                title={it.title}
              />
            ))}
          </div>
        </div>
      )}
    </CommonModalGreenMobile>
  );
};
