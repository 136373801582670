import React from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { Slider, SliderPageType } from "../../../../components/slider/slider";
import { SliderMobile } from "../../../../components/sliderMobile/sliderMobile";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { useIsMobile } from "../../../../utils/common";

interface AboutUsPageSlidesBlockType {
  title: string;
  slides: SliderPageType[];
  slidesmobile: SliderPageType[];
  pageindicatoroverlaydesktop?: boolean;
  pageindicatoroverlaymobile?: boolean;
}

export const AboutUsPageSlidesBlock = ( {
                                          titleShown,
                                          blocksShown,
                                          mobile = false,
                                          content,
                                        }: DesktopBlockPropsType & { content: AboutUsPageSlidesBlockType } ) => {
  const isMobile = useIsMobile();

  return (
    <CommonBlock>
      <div className={ styles.container }>
        <AnimatedBlock isShown={ mobile || titleShown } className={ styles.title } type='h2'>
          { content.title }
        </AnimatedBlock>

        <AnimatedBlock isShown={ mobile || blocksShown }>
          { isMobile ? (
            <SliderMobile pages={ content.slidesmobile }/>
          ) : (
            <Slider
              indicatorOverlay={ content.pageindicatoroverlaydesktop }
              pages={ content.slides }
            />
          ) }
        </AnimatedBlock>
      </div>
    </CommonBlock>
  );
};
