import React, {useRef, useState} from "react";
import styles from "./copyButton.module.scss";
import CopyIcon from "../../assets/icons/copy.svg";
import CopyPressedIcon from "../../assets/icons/copy_pressed.svg";
import {replaceLinks} from "../../utils/replaceLinks";

const copyTableToClipboard = (table: HTMLTableElement) => {
  const range = document.createRange();
  range.selectNode(table);
  window.getSelection()!.removeAllRanges();
  window.getSelection()!.addRange(range);
  document.execCommand("copy");
  window.getSelection()!.removeAllRanges();
}

export const CopyButton = ({
  title,
  className,
  copyData = [],
}: {
  title?: string;
  className?: string;
  copyData?: {
    title: string;
    info?: string;
  }[];
}) => {
  const [justPressed, setJustPressed] = useState(false)
  const tableRef = useRef<HTMLTableElement>(null)
  const _onClick = () => {
    tableRef.current && copyTableToClipboard(tableRef.current)
    setJustPressed(true)
  }
  return (<>
    <div className={[styles.container, className || ""].join(" ")}
         onClick={_onClick}
         onMouseLeave={() => setJustPressed(false)}>
      <span className={styles.title}>{title}</span>

      <img className={styles.copy} src={CopyIcon} />

      <img className={styles.copyPressed} src={CopyPressedIcon} />

      <span className={styles.copy_label}>{!justPressed ? "Скопировать" : "Скопировано"}</span>
    </div>
    <table ref={tableRef} className={styles.copyTable} border={1}>
      {copyData.map(({title, info}) => <tr>
        <td>{title}</td>
        <td dangerouslySetInnerHTML={{
          __html: replaceLinks(info || '')
        }}/>
      </tr>)}
    </table>
  </>);
};
