import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { ListItem } from "../../../../components/listItem/listItem";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { PageMarker } from "../../../../components/pageMarker/pageMarker";

interface Function {
  title: string;
  description: string;
  icon: string;
}

interface OptionsPageFunctionsBlockType {
  title: string;
  functions: Array<{ perpage: Array<Function> }>;
  pageindicatoroverlaydesktop?: boolean;
  pageindicatoroverlaymobile?: boolean;
}

export const OptionsPageFunctionsBlock = ( {
                                             blocksShown,
                                             titleShown,
                                             mobile = false,
                                             content,
                                           }: DesktopBlockPropsType & { content: OptionsPageFunctionsBlockType } ) => {
  const [ currentSlideIdx, setCurrentSlideIdx ] = useState( 0 );

  const containerRef = useRef<HTMLDivElement>( null );

  useEffect( () => {
    const scrollToSelected = () => {
      if ( !containerRef.current ) return;

      const { x } =
        containerRef.current.children[ currentSlideIdx ].getBoundingClientRect();

      console.log( "x", x );

      containerRef.current.scrollBy( {
        left: x,
        behavior: "smooth",
      } );
    };

    scrollToSelected();

    window.addEventListener( "resize", scrollToSelected );
    return () => {
      window.removeEventListener( "resize", scrollToSelected );
    };
  }, [ currentSlideIdx ] );

  return (
    <CommonBlock fullWidth>
      <div className={ styles.mainContainer }>
        <AnimatedBlock isShown={ mobile || titleShown } className={ styles.title } type='h2'>
          { content.title }
        </AnimatedBlock>

        <div ref={ containerRef } className={ styles.container }>
          { content.functions.map( ( _slide ) => (
            <div className={ styles.wrapper }>
              <div className={ styles.slide }>
                <AnimatedBlock
                  isShown={ mobile || blocksShown }
                  className={ styles.leftContainer }
                >
                  { _slide.perpage.map(
                    ( it, index ) =>
                      index % 2 === 0 && (
                        <ListItem option className={ styles.item } { ...it } />
                      )
                  ) }
                </AnimatedBlock>
                <AnimatedBlock
                  latency={ 300 }
                  isShown={ mobile || blocksShown }
                  className={ styles.rightContainer }
                >
                  { _slide.perpage.map(
                    ( it, index ) =>
                      index % 2 === 1 && (
                        <ListItem option className={ styles.item } { ...it } />
                      )
                  ) }
                </AnimatedBlock>
              </div>
            </div>
          ) ) }
        </div>

        <div className={ styles.pageMarkerWrapper }>
          <div
            // onClick={() =>
            //   setCurrentSlideIdx((prevState) =>
            //     prevState + 1 >= content.functions.length ? 0 : prevState + 1
            //   )
            // }
            className={ styles.pageMarkerContainer }
          >
            <PageMarker
              onPageSelect={ setCurrentSlideIdx }
              currentPage={ currentSlideIdx }
              pages={ content.functions.length }
              overlay={ content.pageindicatoroverlaydesktop }
            />
          </div>
        </div>

        <div className={ styles.mobileContainer }>
          { content.functions.map( ( it, index ) =>
            it.perpage.map( ( _function ) => (
              <ListItem option className={ styles.item } { ..._function } />
            ) )
          ) }
        </div>
      </div>
    </CommonBlock>
  );
};
