import { useState } from "react";
import _ from "lodash";
import { useEventListener } from "usehooks-ts";

export const getScrollProgress = () => {
  const { scrollTop, clientHeight } = document.documentElement;
  return ( scrollTop / clientHeight ) + 0.05
}

export const getCurrentPageIdx = () => Math.floor( getScrollProgress() + 0.5 )

export const useCurrentPageIdx = () => {
  const [ currentPageIdx, _setCurrentPageIdx ] = useState( getCurrentPageIdx );

  useEventListener( "scroll", () => {
    const setCurrentPageIdx = _.throttle( _setCurrentPageIdx, 250 )
    setCurrentPageIdx( getCurrentPageIdx() );
  }, undefined, { passive: true } )

  return currentPageIdx
}
