import { useState } from "react";
import styles from "./bottomTabs.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import { MenuType } from "../../pages/program/types/blockTypes";
import { LazyImage } from "../slider/LazyImage";

export const BottomTabs = ({
  className,
  content,
}: {
  className?: string;
  content: MenuType;
}) => {
  const location = useLocation();

  return (
    <div className={[styles.container, className].join(" ")}>
      {content.options.map((it) => (
        <BottomTab
          icon={it.icon ? it.icon : ""}
          title={it.title}
          isActive={it.to === location.pathname}
          to={it.to}
        />
      ))}
    </div>
  );
};

const BottomTab = ({
  icon,
  title,
  isActive,
  to,
}: {
  icon: any;
  title: string;
  isActive?: boolean;
  to?: string;
}) => {
  return (
    <NavLink
      to={to || ""}
      className={[styles.tab, isActive ? styles.active : ""].join(" ")}
    >
      <LazyImage image={icon} />
      <p className={styles.tabTitle}>{title}</p>
    </NavLink>
  );
};
