import { useState } from "react";
import { useEventListener } from "usehooks-ts";
import { scrollToElementByIndex } from "./utils";

export const DESKTOP_BREAKPOINT_WIDTH = 1440;
export const TABLET_BREAKPOINT_WIDTH = 1280;
export const MOBILE_BREAKPOINT_WIDTH = 960;

export const horizontalPadding = () => {
  const { clientWidth } = document.documentElement;
  if ( clientWidth >= DESKTOP_BREAKPOINT_WIDTH ) {
    return 120;
  }
  if ( clientWidth >= TABLET_BREAKPOINT_WIDTH ) {
    return 40;
  }
  return 20;
};

export const useEventMemo = <K extends keyof WindowEventMap, V>(
  type: K,
  fn: () => V
) => {
  const [ value, setValue ] = useState( fn() );
  useEventListener(
    type,
    () => {
      setValue( fn() );
    },
    undefined,
    { passive: true }
  );
  return value;
};

export const useIsMobile = () =>
  useEventMemo(
    "resize",
    () => document.documentElement.clientWidth < MOBILE_BREAKPOINT_WIDTH
  );

export const handleBlockScroll = (
  index: number,
  offsets: Record<number, number> = {
    1: 45,
  }
) => {
  return scrollToElementByIndex( index );

  // fixme: should've been using the block's ref or scrollIntoView to get position
  const offset = offsets[ index ] || 0;
  document.documentElement.scrollTo( {
    top: Math.floor( index ) * document.documentElement.clientHeight - offset,
    behavior: "smooth",
  } );
};
