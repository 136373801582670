export const appealTopics = [
  "Запрос на досрочный выкуп",
  "Запрос на переуступку на третье лицо",
  "Частично-досрочное погашение",
  "Запрос на временную выдачу  второго ключа ",
  "Запрос на временную выдачу ПТС/ПСМ ",
  "Запрос на выдачу распорядительного письма",
  "Запрос на выезд за границу",
  "Запрос на передачу ПЛ в субаренду",
  "Запрос на перерегистрацию ТС/ смену гос.знаков",
  "Запрос на установку доп.оборудования на ПЛ",
];
