import { useContext, useEffect, useState } from "react";
import { CalculatorSlider } from "../../../components/calculatorSlider/calculatorSlider";
import styles from "./calculator.module.scss";
import { CalcButton } from "../../../components/calcButton/calcButton";
import info from "../../../assets/icons/info.svg";
import infoActive from "../../../assets/icons/info_active.svg";
import { Button } from "../../../components/button/button";
import { useIsMobile } from "../../../utils/common";
import { ModalOptionType } from "../../../components/modals/modalOption/modalOption";
import { useLocation, useNavigate } from "react-router-dom";
import { CalculatorContext } from "../../template/template";
import { CalculatorContent } from "../../program/types/blockTypes";

function PMT( ir: number, np: number, pv: number, fv?: number, type?: number ) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || ( fv = 0 );
  type || ( type = 0 );

  if ( ir === 0 ) return -( pv + fv ) / np;

  pvif = Math.pow( 1 + ir, np );
  pmt = ( -ir * ( pv * pvif + fv ) ) / ( pvif - 1 );

  if ( type === 1 ) pmt /= 1 + ir;

  return pmt;
}

export const Calculator = (
  {
    onApplicationButtonPress,
    title,
    onInfoMobilePress = () => {
    },
    content,
  }: {
    onApplicationButtonPress?: () => void;
    onInfoMobilePress?: ( modalName: string ) => void;
    title?: string;
    content: CalculatorContent;
  } ) => {
  // TODO: Extract constants to be configurable from admin page, yet obfuscated
  const [ total, setTotal ] = useState( content.mintotal );
  const [ firstPayment, setFirstPayment ] = useState( 0 );
  const [ months, setMonths ] = useState( content.minmonths );

  const { calculation, setCalculation } = useContext( CalculatorContext );

  useEffect( () => {
    setCalculation( {
      total,
      firstPayment,
      monthlyPayment,
      sumContract,
      minmonths: months,
    } );
  }, [ total, firstPayment, months ] );

  const isMobile = useIsMobile();

  // used for calculations if the entered value is incorrect
  const safeTotal = Math.min(
    Math.max( content.mintotal, total ),
    content.maxtotal
  );
  const safeMonths = Math.max( content.minmonths, months );

  const monthlyPayment = -Math.round(
    PMT(
      content.yearlypercentage / 12 / 100,
      safeMonths,
      safeTotal - firstPayment
    )
  );

  const sumContract = monthlyPayment * safeMonths;
  const economy = Math.round( sumContract / 3 );
  const spending = sumContract - economy;

  const maxFirstPayment = Math.ceil( safeTotal * 0.49 );

  const [ showSumContract, setShowSumContract ] = useState<number | null>( null );

  const handleModals = ( index: number ) => {
    if ( isMobile ) {
      onInfoMobilePress( `calcinfo_${ index }` );
    } else {
      showSumContract === index
        ? setShowSumContract( null )
        : setShowSumContract( index );
    }
  };

  const navigate = useNavigate();

  const location = useLocation();

  return (
    <div className={ styles.outterContainer }>
      { title && <h2 className={ styles.title }>{ title }</h2> }
      <div className={ styles.mainContainer }>
        <div className={ styles.leftContainer }>
          <CalculatorSlider
            title={ "Стоимость лизинга" }
            unitsMin={ "1 млн" }
            unitsMax={ "50 млн" }
            minValue={ content.mintotal }
            maxValue={ content.maxtotal }
            unitsFullValue={ "₽" }
            className={ styles.slider }
            value={ total }
            setValue={ setTotal }
          />
          <CalculatorSlider
            title={ "Первоначальный взнос" }
            unitsMin={ `${ content.minfirstpayment / 1000 } тыс.` }
            unitsMax={ `${ ( maxFirstPayment / 1000_000 ).toFixed( 1 ) } млн` }
            minValue={ 0 }
            maxValue={ maxFirstPayment }
            unitsFullValue={ "₽" }
            className={ styles.slider }
            value={ firstPayment }
            setValue={ setFirstPayment }
          />
          <CalculatorSlider
            title={ "Срок лизинга" }
            unitsMin={ `${ content.minmonths } мес.` }
            unitsMax={ `${ content.maxmonths } мес.` }
            minValue={ content.minmonths }
            maxValue={ content.maxmonths }
            unitsFullValue={ "месяцев" }
            value={ months }
            setValue={ setMonths }
          />
        </div>

        <div className={ styles.delimiter }/>

        <div className={ styles.rightContainer }>
          <div className={ styles.monthlyPayment }>
            <div className={ styles.calc }>
              <CalcButton
                onClick={ () =>
                  setMonths( ( prevState ) =>
                    prevState + 1 < 59 ? prevState + 1 : 59
                  )
                }
                size={ isMobile ? "40px" : "30px" }
                type={ "minus" }
              />
              <p className={ styles.monthlyPaymentSumm }>
                { monthlyPayment.toLocaleString( "ru" ) } ₽
              </p>
              <CalcButton
                onClick={ () =>
                  setMonths( ( prevState ) =>
                    prevState - 1 > 12 ? prevState - 1 : 12
                  )
                }
                size={ isMobile ? "40px" : "30px" }
              />
            </div>
            <p
              className={ [ styles.smallText, styles.smallText__title ].join( " " ) }
            >
              Ежемесячный платёж
            </p>
          </div>

          <div className={ styles.calculatedSumms }>
            <Summary
              summ={ sumContract }
              onInfoPress={ ( it ) => handleModals( 1 ) }
              showInfo={ showSumContract === 1 }
              { ...content.infoblocks.calcinfo_1 }
            />
            <Summary
              onInfoPress={ ( it ) => handleModals( 2 ) }
              showInfo={ showSumContract === 2 }
              summ={ economy }
              { ...content.infoblocks.calcinfo_2 }
            />
            <Summary
              onInfoPress={ ( it ) => handleModals( 3 ) }
              showInfo={ showSumContract === 3 }
              summ={ spending }
              className={ styles.summaryContainer__right }
              { ...content.infoblocks.calcinfo_3 }
            />
          </div>
          <div className={ styles.buttonContainer }>
            <Button
              onClick={ onApplicationButtonPress }
              title={ "Оставить заявку" }
              color={ isMobile ? "green" : "black" }
              size={ "average" }
              className={ styles.button }
            />
            <div className={ styles.bottomText }>
              Расчёты носят ознакомительный характер и не являются офертой.
              Обсудим комфортные для вас условия после отправки заявки
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Summary = ( {
                    summ,
                    title,
                    onInfoPress,
                    className,
                    description,
                    showInfo = false,
                  }: {
  summ: number;
  title: string;
  onInfoPress: ( it: ModalOptionType ) => void;
  className?: string;
  description: string;
  showInfo?: boolean;
} ) => {
  const content = {
    mobileModalCategory: title,
    description: description,
  };
  return (
    <div className={ [ styles.summaryContainer, className ].join( " " ) }>
      <div className={ styles.summaryTop }>
        <p className={ styles.summ }>{ summ.toLocaleString( "ru" ) } ₽</p>
        <div
          className={ styles.iconContainer }
          onClick={ () => onInfoPress( content ) }
        >
          <img src={ info } className={ styles.icon }/>
          <img src={ infoActive } className={ styles.iconActive }/>
        </div>
      </div>
      <p className={ styles.smallText }>{ title }</p>
      <div
        className={ [
          showInfo
            ? styles.summaryInfoContainer
            : styles.summaryInfoContainer__hidden,
        ].join( " " ) }
      >
        { description }
      </div>
    </div>
  );
};
