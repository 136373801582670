import {useEffect, useState} from "react";
import styles from "./calculatorSlider.module.scss";
import ReactSlider from "react-slider";
// @ts-ignore
import AutosizeInput from 'react-18-input-autosize';

export const CalculatorSlider = ({
  unitsMin,
  unitsMax,
  unitsFullValue,
  minValue,
  maxValue,
  title,
  className,
  value,
  setValue,
}: {
  unitsMin?: string;
  unitsMax?: string;
  unitsFullValue?: string;
  minValue: number;
  maxValue: number;
  title: string;
  className?: string;
  value: number;
  setValue: (a: number) => void;
}) => {
  useEffect(() => {
    if(value > maxValue)
      setValue(maxValue)
  }, [maxValue])
  const onBlur = () => {
    if(value < minValue) setValue(minValue)
    if(value > maxValue) setValue(maxValue)
  }
  const renderedValue = value.toLocaleString('ru')
  return (
    <div className={[styles.container, className].join(" ")}>
      {minValue < maxValue ? (
        <p className={styles.value}>
          <AutosizeInput
            value={renderedValue}
            onChange={(ev: any) => {
              const cleanValue = ev.target.value.replace(/[^\d]/g, '')
              if(cleanValue.length === 0) {
                setValue(0)
                return
              }
              const maxLength = maxValue.toString().length
              const int = parseInt(cleanValue.substring(0, maxLength))
              if(isNaN(int))
                return
              setValue(int)
            }}
            onBlur={onBlur}/> {unitsFullValue}
        </p>
      ) : (
        <p className={[styles.value, styles.value__error].join(" ")}>
          Incorrect interval
        </p>
      )}
      <ReactSlider
        className={styles.slider}
        thumbClassName={styles.thumb}
        renderTrack={(props, state) => (
          <div
            {...props}
            className={[
              styles.track,
              state.index === 1 && styles.track__white,
            ].join(" ")}
          />
        )}
        value={value}
        onChange={(a) => setValue(a)}
        min={minValue}
        max={maxValue}
      />
      <div className={styles.bottomContainer}>
        <p className={styles.bottomText}>{title}</p>
        <p className={styles.bottomText}>
          {unitsMin} / {unitsMax}
        </p>
      </div>
    </div>
  );
};
