import React from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";

interface AboutUsPageMissionBlockType {
  title: string;
  text: string;
}

export const AboutUsPageMissionBlock = (
  {
    titleShown,
    blocksShown,
    mobile = false,
    content,
  }: DesktopBlockPropsType & { content: AboutUsPageMissionBlockType } ) => {
  return (
    <CommonBlock>
      <div className={ styles.container }>
        <AnimatedBlock isShown={ mobile || titleShown } className={ styles.title } type='h2'>
          { content.title }
        </AnimatedBlock>
        <AnimatedBlock isShown={ mobile || blocksShown }>
          <div className={ styles.text }>{ content.text }</div>
        </AnimatedBlock>
      </div>
    </CommonBlock>
  );
};
