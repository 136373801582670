import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { PageMarker } from "../../../../components/pageMarker/pageMarker";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import _ from "lodash";

interface ProgramPageConditionsBlockContentType {
  conditionlist: { item: string; title?: string; subtitle?: string }[];
  pageindicatoroverlaydesktop?: boolean;
  pageindicatoroverlaymobile?: boolean;
}

export const ProgramPageConditionsBlock = ( {
                                              blocksShown,
                                              content,
                                            }: DesktopBlockPropsType & {
  content: ProgramPageConditionsBlockContentType;
} ) => {
  const terms = _.chunk( content.conditionlist, 4 );

  const [ currentSlideIdx, setCurrentSlideIdx ] = useState( 0 );

  const containerRef = useRef<HTMLDivElement>( null );

  const markerRef = useRef<HTMLDivElement>( null );

  useEffect( () => {
    if ( containerRef.current && markerRef.current ) {
      //@ts-ignore
      let lastChild = containerRef.current.children[ currentSlideIdx ].lastElementChild.lastElementChild as HTMLDivElement;
      markerRef.current.style.top = ( lastChild?.offsetTop + lastChild?.clientHeight + 30 ) + 'px';
    }

    const scrollToSelected = () => {
      if ( !containerRef.current ) return;

      const { x } =
        containerRef.current.children[ currentSlideIdx ].getBoundingClientRect();
      containerRef.current.scrollBy( {
        left: x,
        behavior: "smooth",
      } );
    };

    scrollToSelected();

    window.addEventListener( "resize", scrollToSelected );
    return () => {
      window.removeEventListener( "resize", scrollToSelected );
    };
  }, [ currentSlideIdx ] );

  return (
    <CommonBlock fullWidth>
      <div className={ styles.mobileContainer }>
        { terms.flat().map( ( it ) => (
          // @ts-ignore
          <ListItem item={ it }/>
        ) ) }
      </div>

      <div ref={ containerRef } className={ styles.container }>
        { terms.map( ( _slide ) => (
          <AnimatedBlock isShown={ blocksShown } className={ styles.wrapper }>
            <div className={ styles.slide }>
              { _slide.map( ( it ) => (
                // @ts-ignore
                <ListItem item={ it }/>
              ) ) }
            </div>
          </AnimatedBlock>
        ) ) }
      </div>

      <div ref={ markerRef } className={ styles.pageMarkerWrapper }>
        <div className={ styles.pageMarkerContainer }>
          <PageMarker
            currentPage={ currentSlideIdx }
            pages={ terms.length }
            overlay={ content.pageindicatoroverlaydesktop }
            onPageSelect={ setCurrentSlideIdx }
          />
        </div>
      </div>
    </CommonBlock>
  );
};

const ListItem = ( {
                     item,
                   }: {
  item: {
    item: string;
    title: string[] | string;
    subtitle: string;
  };
} ) => {
  return (
    <div className={ styles.term }>
      <p className={ styles.title }>{ item.item }</p>
      <div className={ styles.descriptionContainer }>
        { typeof item.title === "object"
          ? item.title.map( ( description_string ) => (
            <p className={ styles.description }>{ description_string }</p>
          ) )
          : item.title && (
          <p
            className={ [
              styles.description,
              ( item.title && styles.description__margin ) || "",
            ].join( " " ) }
          >
            { item.title }
          </p>
        ) }
        { item.subtitle && (
          <p
            className={ [ styles.description, styles.description__light ].join(
              " "
            ) }
          >
            { item.subtitle }
          </p>
        ) }
      </div>
    </div>
  );
};
