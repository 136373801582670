import styles from "./breadcrumbs.module.scss";
import React from "react";

export interface BreadcrumbDef {
  title: string;
  onClick?: () => void;
}

export const Breadcrumbs = ({
  pageNames,
  path = [],
  currentPage,
  blackIndexes = [],
}: {
  pageNames: string[];
  currentPage: number;
  blackIndexes?: number[];
  path?: BreadcrumbDef[];
}) => {
  return (
    <span
      className={
        styles.header__text +
        " " +
        (blackIndexes.includes(currentPage) ? styles.header__text_black : "")
      }
    >
      {path.map(({ title, onClick }) => (
        <>
          <span onClick={onClick} className={styles.header__text__clickable}>
            {title}
          </span>
          <span className={styles.header__text__slash}>/</span>
        </>
      ))}
      <span>{pageNames[(currentPage || 1) - 1]}</span>
    </span>
  );
};
