import styles from "./commonModalGreenMobile.module.scss";
import { ButtonMobile } from "../../modalComponentMobile/modalComponentMobile";

export const CommonModalGreenMobile = ({
  className,
  buttonTitle,
  onClose,
  children,
  title,
  titleClassName,
  buttonClassName,
  scrollContainerClassName,
  zeroPadding = false,
}: {
  className?: string;
  buttonTitle: string;
  onClose?: () => void;
  children?: any;
  title?: string;
  titleClassName?: string;
  buttonClassName?: string;
  scrollContainerClassName?: string;
  zeroPadding?: boolean;
}) => {
  return (
    <div
      className={[
        styles.container,
        (zeroPadding && styles.container__zeroPadding) || "",
        className || "",
      ].join(" ")}
    >
      <ButtonMobile
        className={[styles.button, buttonClassName || ""].join(" ")}
        onClose={onClose}
        title={buttonTitle}
      />
      <div
        className={[
          styles.scrollContainer,
          (zeroPadding && styles.scrollContainer__zeroPadding) || "",
          scrollContainerClassName,
        ].join(" ")}
      >
        {title && (
          <p className={[styles.title, titleClassName].join(" ")}>{title}</p>
        )}

        {children}
      </div>
      <div className={styles.bottomGradient} />
    </div>
  );
};
