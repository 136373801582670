import { PromoModalContent } from "../../../pages/program/types/blockTypes";
import { CommonModalGreen } from "../commonModalGreen/commonModalGreen";
import styles from "./modalSpecialOrder.module.scss";

export const ModalSpecialOrder = ({
  isOpen,
  onClose,
  content,
}: {
  isOpen: boolean;
  onClose?: () => void;
  content: PromoModalContent;
}) => {
  return (
    <CommonModalGreen
      onClose={onClose}
      isOpen={isOpen}
      title={content.title}
      titleClassName={styles.title}
      modalClassName={styles.modal}
    >
      <p className={styles.textBig}>{content.description}</p>

      <div className={styles.bottomListContainer}>
        <p
          className={[styles.textMedium, styles.textMedium__heading].join(" ")}
        >
          {content.subtitle}
        </p>
        <ul className={[styles.textMedium].join(" ")}>
          {content.columntexts.map((it) => (
            <li>{it.value}</li>
          ))}
        </ul>
      </div>
      <p className={styles.textSmall}>{content.disclaimer}</p>
    </CommonModalGreen>
  );
};
