export enum BlockTypes {
  MAIN = "main",
  ABOUT = "about",
  PROGRAMS = "programs",
  OPTIONS = "options",
  CALCULATOR = "calculator",
  DOCUMENTS_FILES = "documents_files",
  DOCUMENTS_DESCRIPTION = "documents_description",
  CONDITIONS = "conditions",
  PROMOTION = "promotion",
  QUESTIONS = "questions",
  BENEFITS_TEXT = "benefits_text",
  BENEFITS_CARDS = "benefits_cards",
  FUNCTIONS = "functions",
  COMBINATIONS = "combinations",
  LOYALTY = "loyalty",
  SERVICES = "services",
  PAGE_GUIDE = "page_guide",
  ABOUT_US = "about_us",
  MISSION = "mission",
  SLIDES = "slides",
  LEASING = "leasing",
  CARDS = "cards",
  MANAGEMENT = "management",
  FORM = "form",
  DISCOUNTS = "discounts",
}

export enum ModalTypes {
  OPTION = "option",
  APPLICATION = "application",
  PROMOTION = "promotion",
  CONTACTS = "contacts",
  DETAILS = "details",
}

export type Key = string;

export enum ProgramType {
  standard = "standard",
  express = "express",
  facilities = "facilities",
  preferential = "preferential",
}

export enum OptionsType {
  road_map = "road_map",
  cash_back = "cash_back",
  light_start = "light_start",
  hard_start = "hard_start",
}

export enum DocumentType {
  doc = "doc",
  pdf = "pdf",
}

export type Main = {
  key: Key;
  type: BlockTypes.MAIN;
  content: MainContent;
};

export type MainContent = {
  title: string;
  subtitle: string;
  description: string;
  applicationbutton: string;
};

export type About = {
  key: Key;
  type: BlockTypes.ABOUT;
  content: AboutContent;
};

export type AboutContent = {
  aboutUsList: AboutUsList[];
};

export type AboutUsList = {
  item: string;
  value: string;
  icon: string;
};

export type Options = {
  key: Key;
  type: BlockTypes.OPTIONS;
  content: OptionsContent;
};

export type OptionsContent = {
  optionsData: OptionsData[];
};

export type OptionsData = {
  type: OptionsType;
  icon: string;
  title: string;
  descriptiontop: string;
  description: string;
  ismodal: boolean;
  modaldata?: modaldata;
};

export type modaldata = {
  title: string;
  descriptionheader?: string;
  description: string;
  combineswith?: combineswith[];
};

export type combineswith = {
  type: ProgramType;
  title: string;
};

export type Calculator = {
  key: Key;
  type: BlockTypes.CALCULATOR;
  content: CalculatorContent;
};

export type CalculatorContent = {
  title: string;
  mintotal: number;
  maxtotal: number;
  minfirstpayment: number;
  maxfirstpaymentpercentage: number;
  minmonths: number;
  maxmonths: number;
  yearlypercentage: number;
  infoblocks: infoblocks;
};

export type infoblocks = {
  calcinfo_1: Calcinfo;
  calcinfo_2: Calcinfo;
  calcinfo_3: Calcinfo;
};

export type Calcinfo = {
  title: string;
  description: string;
};

export type DocumentsDescription = {
  key: Key;
  type: BlockTypes.DOCUMENTS_DESCRIPTION;
  style: string;
  content: DocumentsDescriptionContent;
};

export type DocumentsDescriptionContent = {
  title: string;
  documentlist: string[];
};

export type Conditions = {
  key: Key;
  type: BlockTypes.CONDITIONS;
  content: ConditionsContent;
};

export type ConditionsContent = {
  conditionList: ConditionList[];
};

export type ConditionList = {
  item: string;
  title?: string;
  subtitle?: string;
};

export type Questions = {
  key: Key;
  type: BlockTypes.QUESTIONS;
  content: QuestionsContent;
};

export type QuestionsContent = {
  questionList: QuestionList[];
};

export type QuestionList = {
  item: string;
  value: string;
};

export type Promotion = {
  key: Key;
  type: BlockTypes.PROMOTION;
  content: PromotionContent;
};

export type PromotionContent = {
  promos: Promo[];
};

export type Promo = {
  title: string;
  description: string;
  imagedesktop: any;
  imagemobile: any;
  modaldata: PromoModalContent;
};

export type PromoModalContent = {
  title: string;
  description: string;
  subtitle: string;
  columntexts: ObjectString[];
  disclaimer: string;
};

export type BenefitsCards = {
  key: Key;
  type: BlockTypes.BENEFITS_CARDS;
  content: BenefitsCardsContent;
};

export type BenefitsCardsContent = {
  title: string;
  benefits: Benefit[];
};

export type Benefit = {
  title: string;
  description: string;
  backgroundImage: string;
  icon: string;
};

export type BenefitsText = {
  key: Key;
  type: BlockTypes.BENEFITS_TEXT;
  content: BenefitsTextContent;
};

export type BenefitsTextContent = {
  title: string;
  subtitle: string;
  items: BenefitsTextItem[];
};

export type BenefitsTextItem = {
  icon: string;
  title: string;
  text: string;
};

export type Functions = {
  type: BlockTypes.FUNCTIONS;
  content: FunctionsContent;
};

export type FunctionsContent = {
  title: string;
  functions: Array<Function[]>;
};

export type Function = {
  title: string;
  description: string;
  icon: string;
};

export type Combinations = {
  type: BlockTypes.COMBINATIONS;
  content: CombinationsContent;
};

export type CombinationsContent = {
  title: string;
  programs: Program[];
};

export type Program = {
  backgroundImage: string;
  type: ProgramType;
  content: ProgramContent;
};

export type ProgramContent = {
  title: string;
  buttonlabel: string;
  descriptionlines: string[];
};

export type Loyalty = {
  key: Key;
  type: BlockTypes.LOYALTY;
  content: LoyaltyContent;
};

export type LoyaltyContent = {
  disclaimer: string;
  items: LoyaltyItem[];
};

export type LoyaltyItem = {
  type: string;
  image: string;
  title: string;
  shorttext: string;
  fulltext: string;
  modaldata?: modaldata;
};

export type Services = {
  key: Key;
  type: BlockTypes.SERVICES;
  content: ServicesContent;
};

export type ServicesContent = {
  services: Service[];
};

export type Service = {
  title: string;
  image: string;
  totitle: string;
  to: string;
};

export type DocumentsFiles = {
  key: Key;
  type: BlockTypes.DOCUMENTS_FILES;
  content: DocumentsFilesContent;
};

export type DocumentsFilesContent = {
  pages: Page[];
};

export type Page = {
  title: string;
  documents: Document[];
};

export type Document = {
  title: string;
  url: string;
  icon: string;
  type: string;
};

export type Guide = {
  key: Key;
  type: BlockTypes.PAGE_GUIDE;
  content: GuideContent;
};

export type GuideContent = {
  title: string;
  columntexts: string[];
  identifiers: string[];
};

export type Programs = {
  key: Key;
  type: BlockTypes.PROGRAMS;
  content: ProgramsContent;
};

export type ProgramsContent = {
  title: string;
  programs: Program[];
};

export type Cards = {
  key: Key;
  type: BlockTypes.CARDS;
  breadcrumb: string;
  content: CardsContent;
};

export type CardsContent = {
  title: string;
  cards: Card[];
};

export type Card = {
  icon: string;
  title: string;
};

export type Form = {
  key: Key;
  type: BlockTypes.FORM;
  content: FormContent;
};

export type FormContent = {
  title: string;
  warning: string;
};

export type Mission = {
  key: Key;
  type: BlockTypes.MISSION;
  content: MissionContent;
};

export type MissionContent = {
  title: string;
  text: string;
};

export type Slides = {
  key: Key;
  type: BlockTypes.SLIDES;
  content: SlidesContent;
};

export type SlidesContent = {
  title: string;
  slides: Slide[];
};

export type Slide = {
  image: string;
  title: string;
  description: string;
};

export type Leasing = {
  key: Key;
  type: BlockTypes.LEASING;
  content: LeasingContent;
};

export type LeasingContent = {
  title: string;
  subtitle: string;
  items: LeasingItem[];
};

export type LeasingItem = {
  icon: string;
  title: string;
  text: string;
};

export type Management = {
  key: Key;
  type: BlockTypes.MANAGEMENT;
  content: ManagementContent;
};

export type ManagementContent = {
  title: string;
  stuff: Stuff[];
};

export type Stuff = {
  name: string;
  duty: string;
  avatar: string;
};

export type AboutUs = {
  key: string;
  type: BlockTypes.ABOUT_US;
  content: AboutUsContent;
};

export type AboutUsContent = {
  title: string;
  columntexts: string[];
};

export type ObjectNumber = {
  value: number;
};

export type ObjectString = {
  value: string;
};

export type MenuType = {
  options: MenuItemContent[];
  loginbutton: LoginButton;
};

export type MenuItemContent = {
  icon: any[];
  to: string;
  title: string;
};
export type LoginButton = {
  to: string;
  title: string;
  isshown: boolean;
};

export type SearchType = {
  pagetitles: object;
  searchdefinitions: SearchDefinition[];
};

export type SearchDefinition = {
  location: string;
  terms: ObjectString[];
};

export interface MainBlockSlideType {
  title: string;
  background: string;
  backgroundGradient: string;
  backgroundMobile: string;
  backgroundBlurGradient: string;
}

export interface MainBlockType {
  slides: MainBlockSlideType[];
  selectedMainSlideIdx: number;
  onSelectNextSlide: () => void;
}
