import React, { useEffect, useMemo, useRef, useState } from "react";
import { CardWithImage } from "../cardWithImage/cardWithImage";
import styles from "./featuresContainerMobile.module.scss";
import { PageMarker } from "../pageMarker/pageMarker";

export const FeaturesContainerMobile = ({
  className,
  features,
  indicatorOverlay = false,
}: {
  features: Array<any>;
  className?: string;
  indicatorOverlay?: boolean;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [scroll, setScroll] = useState<number>(0);

  const [pageNumber, setPageNumber] = useState<number>(1);

  const cardWidth = useMemo(
    () => document.documentElement.clientWidth - 36,
    [document.documentElement.clientWidth]
  );

  useEffect(() => {
    setPageNumber(Math.ceil(scroll / cardWidth + 0.5));
  }, [scroll]);

  return (
    <div className={[styles.outterContainer, className].join(" ")}>
      <div
        ref={scrollRef}
        onScroll={(e) => {
          setScroll(e.currentTarget.scrollLeft);
        }}
        className={styles.mainContainer}
        dir="ltr"
      >
        {features.map((it, index) => (
          <CardWithImage
            active
            containerClassName={[
              styles.card,
              // (index + 1 === features.length && styles.card__last) || "",
            ].join(" ")}
            {...it}
            backgroundImage={it.backgroundimage}
            type={it.type}
            content={it.content[0]}
          />
        ))}
        <div className={styles.specialPlaceholder}>
          <div className={styles.specialPlaceholder} />
        </div>
      </div>
      <div className={styles.markerContainer}>
        <PageMarker
          currentPage={pageNumber - 1}
          pages={features.length}
          overlay={indicatorOverlay}
        />
      </div>
    </div>
  );
};
