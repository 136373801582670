import { useLocation, useNavigate } from "react-router-dom";
import { ProgramType } from "../../../pages/options/blocks/combinations";
import { Button } from "../../button/button";
import { CommonModalGreen } from "../commonModalGreen/commonModalGreen";
import styles from "./modalOption.module.scss";

export interface ProgramButtonType {
  type: ProgramType;
  title: string;
}

export interface ModalOptionType {
  mobileModalCategory?: string;
  title?: string;
  descriptionheader?: string;
  description: string;
  combineswith?: ProgramButtonType[];
}

export const ModalOption = ({
  content,
  isOpen,
  onClose,
}: {
  content: ModalOptionType | null;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const programType = location.pathname.slice(9);
  return (
    <CommonModalGreen
      onClose={onClose}
      isOpen={isOpen}
      title={content?.title}
      titleClassName={styles.content__title}
    >
      <div className={[styles.content, styles.content__heading].join(" ")}>
        {content?.descriptionheader}
      </div>
      <div className={styles.content}>{content?.description}</div>

      {content?.combineswith && (
        <>
          <div className={[styles.content, styles.content__heading].join(" ")}>
            Сочетается со следующими программами:
          </div>
          <div className={styles.bottomListContainer}>
            {content?.combineswith?.map((it) => (
              <Button
                onClick={() => {
                  if (programType === it.type.toString()) {
                    navigate("/program/" + it.type + "#main");
                  } else {
                    navigate("/program/" + it.type);
                  }
                  onClose();
                }}
                color={"white"}
                className={styles.button}
                title={it.title}
              />
            ))}
          </div>
        </>
      )}
    </CommonModalGreen>
  );
};
