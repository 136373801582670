import styles from "./circleButtonContainer.module.scss";

export const CircleButtonContainer = ({
  className,
  children,
  onClick,
  disabled = false,
  withBlur = false,
  color,
  size = "30px",
}: {
  className?: string;
  children?: any;
  onClick?: () => void;
  disabled?: boolean;
  withBlur?: boolean;
  color: "white" | "black" | "calc";
  size?: "30px" | "40px";
}) => {
  const buttonColors = {
    white: styles.container__white,
    black: styles.container__black,
    calc: styles.container__calc,
  };

  const buttonSize = {
    "30px": styles.container__30,
    "40px": styles.container__40,
  };
  return (
    <div
      children={children}
      onClick={onClick}
      className={[
        styles.container,
        buttonSize[size],
        withBlur ? styles.container__blur : "",
        buttonColors[color],
        (disabled && styles.container__disabled) || "",
        className || "",
      ].join(" ")}
    />
  );
};
