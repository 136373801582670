import styles from "./dropdownPicker.module.scss";
import React, { CSSProperties, useState } from "react";
import chevronUp from "../../assets/icons/chevron-up_gray.svg";
import chevronDown from "../../assets/icons/chevron-down_gray.svg";
import chevronUpWhite from "../../assets/icons/chevron-up.svg";
import chevronDownWhite from "../../assets/icons/chevron-down.svg";

export const DropdownPicker = (
  {
    options,
    value,
    setValue,
    title,
    className,
    style = "dropdownPicker",
    error,
    stylesheet = {},
  }: {
    options: any[];
    value: any;
    setValue: ( a: any ) => void;
    title?: string;
    className?: string;
    style?: "dropdownPicker" | "inputField" | "inputFieldForm";
    error?: string;
    stylesheet?: CSSProperties
  } ) => {
  const [ showDropdown, setShowDropdown ] = useState( false );

  const onItemClick = ( a: any ) => {
    setValue( a );
    setShowDropdown( false );
  };

  return (
    <div
      className={ [ styles.outterContainer, className ].join( " " ) }
      style={ {
        ...stylesheet,
        //@ts-ignore
        overflow: showDropdown && stylesheet.maxHeight > 0 ? "unset" : stylesheet.overflow
      } }
    >
      <div
        onClick={ () => setShowDropdown( !showDropdown ) }
        className={ [
          styles.container,
          ( showDropdown && styles.container__active ) || "",
          ( style === "inputField" && styles.container__inputField ) || "",
          ( style === "inputFieldForm" && styles.container__inputFieldForm ) ||
          "",
          ( error && styles.container__error ) || "",
        ].join( " " ) }
      >
        { value || title }
        <img
          src={
            style === "inputFieldForm"
              ? showDropdown
                ? chevronUpWhite
                : chevronDownWhite
              : showDropdown
                ? chevronUp
                : chevronDown
          }
          className={ [
            styles.arrow,
            ( style === "inputField" && styles.arrow__inputField ) || "",
            ( style === "inputFieldForm" && styles.arrow__inputFieldForm ) || "",
          ].join( " " ) }
        />
      </div>
      { showDropdown && (
        <DropdownList style={ style } setValue={ onItemClick } { ...{ options } } />
      ) }
      { error &&
          <span className={ styles.container__error }
                style={ { fontSize: 12, opacity: 0.6 } }>{ error }</span>
      }
    </div>
  );
};

const DropdownList = (
  {
    options,
    setValue,
    style = "dropdownPicker",
  }: {
    options: any;
    setValue: ( a: any ) => void;
    style?: "dropdownPicker" | "inputField" | "inputFieldForm";
  } ) => {
  return (
    <div
      className={ [
        styles.dropdownContainer,
        ( !options && styles.dropdownContainer__small ) || "",
        ( style === "inputField" && styles.dropdownContainer__inputField ) || "",
      ].join( " " ) }
    >
      { options.map( ( it: string, index: number ) => (
        <Item title={ it } onClick={ () => setValue( it ) }/>
      ) ) }
    </div>
  );
};

const Item = ( {
                 title,
                 onClick,
                 className,
               }: {
  title: string;
  onClick: () => void;
  className?: string;
} ) => {
  return (
    <div onClick={ onClick } className={ [ styles.listItem, className ].join( " " ) }>
      { title }
    </div>
  );
};
