import { CSSProperties } from "react";
import styles from "./serviceCard.module.scss";
import { Button } from "../button/button";
import { LazyImage } from "../slider/LazyImage";

export const ServiceCard = ({
  className,
  title,
  description,
  descriptiontop,
  titleTextStyle,
  descriptionTextStyle,
  icon,
  onClick,
}: {
  className?: string;
  title: string;
  description: string;
  descriptiontop?: string;
  titleTextStyle?: CSSProperties;
  descriptionTextStyle?: CSSProperties;
  icon?: string;
  onClick?: () => void;
}) => {
  return (
    <div className={[styles.mainContainer, className].join(" ")}>
      <div className={styles.iconContainer}>
        <LazyImage image={icon} className={styles.icon} />
      </div>
      <div className={styles.contentContainer}>
        <p className={styles.titleText} style={titleTextStyle}>
          {title}
        </p>
        {descriptiontop && (
          <p
            className={[
              styles.descriptionText,
              styles.descriptionText__top,
            ].join(" ")}
            style={descriptionTextStyle}
          >
            {descriptiontop}
          </p>
        )}
        <p className={styles.descriptionText} style={descriptionTextStyle}>
          {description}
        </p>
        <Button
          title="Подробнее"
          color={"green"}
          onClick={onClick}
          className={styles.button}
          changeSizeIfMobile
        />
      </div>
    </div>
  );
};
