import { CSSProperties, useMemo, useState } from "react";
import styles from "./sliderMobile.module.scss";
import img1 from "../../assets/images/advantage_1.png";
import img2 from "../../assets/images/advantage_2.png";
import arrow from "../../assets/icons/arrow-white.svg";
import { SliderPageType } from "../slider/slider";
import { LazyImage } from "../slider/LazyImage";

export const SliderMobile = ({
  titleTextStyle,
  descriptionTextStyle,
  className,
  pages,
}: {
  titleTextStyle?: string;
  descriptionTextStyle?: string;
  className?: string;
  pages: SliderPageType[];
}) => {
  const [page, setPage] = useState(0);
  return (
    <div className={[styles.container, className].join(" ")}>
      <Switcher page={page} setPage={setPage} length={pages.length} />

      <LazyImage
        image={pages[page].image}
        className={styles.image}
        key={page.toString()}
      />

      <div className={[styles.lowerContainer].join(" ")}>
        <p className={[styles.titleText, titleTextStyle].join(" ")}>
          {pages[page].title}
        </p>
        <p className={[styles.descriptionText, descriptionTextStyle].join(" ")}>
          {pages[page].description}
        </p>
      </div>
    </div>
  );
};

const Switcher = ({
  page,
  setPage,
  length,
}: {
  page: number;
  setPage: (p: number) => void;
  length: number;
}) => {
  const lastPageNumber = length - 1;

  const onLeft = () => {
    if (page > 0) {
      setPage(page - 1);
    } else {
      setPage(lastPageNumber);
    }
  };

  const onRight = () => {
    if (page < lastPageNumber) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  };

  return (
    <div className={styles.switcher}>
      <img src={arrow} className={styles.arrow} onClick={onLeft} />
      <div className={styles.dotsContainer}>
        {/*// @ts-ignore*/}
        {[...Array(length).keys()].map((it, index) => (
          <Dot
            isAlone={index === lastPageNumber || lastPageNumber === 0}
            isActive={index === page}
          />
        ))}
      </div>
      <img
        src={arrow}
        className={[styles.arrow, styles.arrowRight].join(" ")}
        onClick={onRight}
      />
    </div>
  );
};

const Dot = ({
  isActive,
  isAlone,
}: {
  isActive: boolean;
  isAlone: boolean;
}) => (
  <div
    className={[
      styles.dot,
      isActive && styles.dot__active,
      !isAlone ? styles.dot__notAlone : "",
    ].join(" ")}
  />
);
