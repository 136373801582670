import styles from "./rightButtons.module.scss";
import x from "../../assets/icons/x.svg";
import { SearchButton } from "../searchButton/searchButton";
import React, { PropsWithChildren } from "react";
import { SearchType } from "../../pages/program/types/blockTypes";

export const RightButtons = ({
  className,
  children,
  searchData,
}: PropsWithChildren<{
  className?: string;
  searchData: SearchType;
}>) => {
  return (
    <div className={[styles.container, className].join(" ")}>
      {children}
      <SearchButton searchData={searchData} />
    </div>
  );
};
