import styles from "./modalComponent.module.scss";
import { CloseButton } from "../closeButton/closeButton";
import { ReactNode, useEffect, useMemo } from "react";
import { modals } from "../../utils/modals";

export const ModalComponent = (
  {
    headerButton,
    buttonTitle,
    onButtonClick,
    onClose,
    children,
    scrollable = false,
    className,
  }: {
    buttonTitle: string;
    onButtonClick?: () => void;
    onClose?: () => void;
    children?: ReactNode;
    scrollable?: boolean;
    headerButton?: ReactNode;
    className?: string;
  }) => {
  const key = useMemo(() => Date.now(), []);

  useEffect(() => {
    modals.keys.push(key);
    const closeOnEsc = (e: any) => {
      if ( key !== modals.keys[modals.keys.length - 1] ) {
        return;
      }

      if ( e.key === "Escape" ) {
        if ( onClose !== undefined ) {
          onClose();
        }
      }
    };
    document.addEventListener("keydown", closeOnEsc);

    return () => {
      document.removeEventListener("keydown", closeOnEsc);
      modals.keys.pop();
    };
  }, []);

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.outterContainer }>
        <div className={ [ styles.container, className ].join(" ") }>
          <div onClick={ onButtonClick } className={ styles.header }>
            { buttonTitle }
            { headerButton }
          </div>

          <div
            className={ [
              styles.itemContainer,
              scrollable ? styles.scrollableContainer : "",
            ].join(" ") }
          >
            { children }
          </div>
        </div>
        <CloseButton onClick={ onClose }/>
      </div>
    </div>
  );
};

export const ModalContentContainer = ({
                                        children,
                                        className,
                                        first,
                                      }: {
  children?: any;
  className?: string;
  first?: boolean;
}) => {
  return (
    <div
      className={ [
        styles.contentContainer,
        first ? styles.contentContainer_first : "",
        className || "",
      ].join(" ") }
    >
      { children }
    </div>
  );
};
