import styles from "./pageMarker.module.scss";
// import DotGreen from "../../assets/icons/dot_green.svg";
// import DotGray from "../../assets/icons/dot_gray.svg";
import { useState } from "react";

export const PageMarker = ({
  pages,
  currentPage,
  type = "green",
  overlay = false,
  onPageSelect,
}: {
  pages: number;
  currentPage: number;
  type?: "green" | "black";
  overlay?: boolean;
  onPageSelect?: (index: number) => void;
}) => {
  return (
    <div
      className={[
        styles.container,
        (overlay && styles.container__overlay) || "",
      ].join(" ")}
    >
      {/*// @ts-ignore*/}
      {[...Array(pages).keys()].map((it, index) => (
        <div
          onClick={() => (onPageSelect ? onPageSelect(index) : () => {})}
          className={[
            styles.dot,
            styles["dot_" + type],
            index === currentPage ? styles.dot_active : "",
            index === currentPage ? styles["dot_active_" + type] : "",
          ].join(" ")}
        />
      ))}
    </div>
  );
};
