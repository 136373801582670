import React, { ReactNode } from "react";
import styles from "./headerMobile.module.scss";
import logoIcon from "../../assets/icons/logo.svg";
import { Button } from "../button/button";
import { MenuType, SearchType } from "../../pages/program/types/blockTypes";
import { RightButtons } from "../rightButtons/rightButtons";
import { Link } from "react-router-dom";

export const HeaderMobile = (
  {
    ButtonContainer,
    short = false,
    content,
    search,
  }: {
    ButtonContainer?: ReactNode;
    short?: boolean;
    content: MenuType;
    search: SearchType;
  } ) => {
  return (
    <div className={ styles.fixedContainer }>
      <div className={ styles.wrapper }>
        <div className={ styles.container }>
          <div
            className={ [
              styles.topContainer,
              short ? styles.topContainer_short : "",
            ].join( " " ) }
          >
            <Link to="/">
              <img src={ logoIcon } className={ styles.logo }/>
            </Link>

            <RightButtons searchData={ search }></RightButtons>

            { content.loginbutton.isshown && (
              <Button
                className={ styles.enterButton }
                size={ "average" }
                color={ "white" }
                title={ content.loginbutton.title }
                to={ content.loginbutton.to }
              />
            ) }
          </div>

          { ButtonContainer }
        </div>
        {/*<div className={styles.gradient} />*/ }
      </div>
    </div>
  );
};
