import React, { ReactNode, useState } from "react";
import styles from "./header.module.scss";
import logoIcon from "../../assets/icons/logo.svg";
import logoPlainIcon from "../../assets/icons/logo_plain.svg";
import logoPlainBlackIcon from "../../assets/icons/logo_plain_black.svg";
import { Button } from "../button/button";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useEventMemo } from "../../utils/common";
import { getScrollProgress } from "../../utils/useCurrentPageIdx";
import { MenuItemContent, MenuType, } from "../../pages/program/types/blockTypes";

export const Header = (
  {
    isBlackInner = false,
    className,
    pageCounter,
    TextComponent,
    content,
  }: {
    className?: string;
    isBlackInner?: boolean;
    pageCounter?: number;
    TextComponent?: ReactNode;
    content: MenuType;
  } ) => {
  const [ revealedOnClick, setRevealedOnClick ] = useState( false );
  const hidden = useEventMemo( "scroll", () => getScrollProgress() > 0.5 );
  const [ scrollingDown, setScrollingDown ] = useState( false );

  const shouldntShow = hidden && !revealedOnClick && scrollingDown;

  const [ lastScrollProgress, setLastScrollProgress ] = useState( 0 );

  useEventMemo( "scroll", () => {
    const scrollProgress = getScrollProgress();
    if ( scrollProgress > lastScrollProgress ) {
      setScrollingDown( true );
      setLastScrollProgress( scrollProgress );
    } else if ( scrollProgress < lastScrollProgress ) {
      setScrollingDown( false );
      setRevealedOnClick( false );
      setLastScrollProgress( scrollProgress );
    }
  } );

  return (
    <div className={ [ styles.innerWrapper, className ].join( " " ) }>
      <div
        className={
          styles.wrapper + " " + ( shouldntShow ? styles.wrapper__hide : "" )
        }
      >
        <div
          className={
            styles.container +
            " " +
            ( shouldntShow ? styles.container__hide : "" )
          }
        >
          <Link to="/">
            <img
              src={ logoIcon }
              onClick={ () => setRevealedOnClick( false ) }
              className={ styles.logo }
            />
          </Link>
          <div className={ styles.itemList }>
            { content.options.map( ( item ) => (
              <HeaderItem { ...item } />
            ) ) }
          </div>
          { content.loginbutton.isshown && (
            <Button
              className={ styles.button }
              color={ "white" }
              title={ content.loginbutton.title }
              to={ content.loginbutton.to }
            />
          ) }
        </div>

        <div className={ styles.inside }>
          <Link to="/">
            <img
              className={ styles.logo_plain }
              src={ isBlackInner ? logoPlainBlackIcon : logoPlainIcon }
              onClick={ () => setRevealedOnClick( true ) }
            />
          </Link>
          <span
            className={
              styles.inside__text +
              " " +
              ( isBlackInner ? styles.inside__text_black : "" )
            }
          >
            { ( pageCounter || 0 ).toString().padStart( 2, "0" ) }/
          </span>
          <span
            className={
              styles.text_component +
              " " +
              ( shouldntShow ? "" : styles.text_component__hidden )
            }
          >
            { TextComponent }
          </span>
        </div>
      </div>
    </div>
  );
};

const HeaderItem = ( item: MenuItemContent ) => {
  const location = useLocation();
  const isActive = item.to === location.pathname;
  return (
    <NavLink
      to={ isActive ? "" : item.to }
      className={ [ styles.item, isActive && styles.item__active ].join( " " ) }
      onClick={
        isActive
          ? () =>
            window.scrollTo( {
              top: 0,
              left: 0,
              behavior: "smooth",
            } )
          : () => {
          }
      }
    >
      { item.title }
    </NavLink>
  );
};
