import { RefObject, useCallback, useLayoutEffect } from "react";
import { horizontalPadding } from "./common";
import { getScrollProgress } from "./useCurrentPageIdx";
import { useEventListener } from "usehooks-ts";

export const _getTabTransform = () => {
  const { clientHeight, clientWidth } = document.documentElement;

  const left =
    clientWidth - 1690 > 0 ? (clientWidth - 1440) / 2 : horizontalPadding();
  const top =
    clientHeight -
    Math.min(140 + clientHeight * getScrollProgress(), clientHeight - 130);
  return `translate(${left}px, ${top}px)`;
};

export const useTabScroller = (tabContainer: RefObject<HTMLDivElement>) => {
  const setTransform = useCallback(() => {
    if (tabContainer.current) {
      tabContainer.current.style.transform = _getTabTransform();
    }
  }, [tabContainer]);
  useLayoutEffect(setTransform, [tabContainer.current]);
  useEventListener("scroll", setTransform, undefined, { passive: true });
  useEventListener("resize", setTransform, undefined, { passive: true });
};
