import styles from "./featureCard.module.scss";
import { GradientContainer } from "../gradientContainer/gradientContainer";
import { LazyImage } from "../slider/LazyImage";

export const FeatureCard = ({
  title,
  description,
  descriptionComponent,
  icon,
  className,
  small,
}: {
  title: string;
  description?: string;
  descriptionComponent?: any;
  icon?: any;
  className?: string;
  small?: boolean;
}) => {
  return (
    <GradientContainer className={className}>
      <LazyImage className={styles.icon} image={icon} />
      <div className={[styles.contentContainer].join(" ")}>
        <div className={styles.titleText}>{title}</div>

        {descriptionComponent ? (
          descriptionComponent
        ) : (
          <div className={styles.descriptionText}>{description}</div>
        )}
      </div>
    </GradientContainer>
  );
};
