import plus from "../../assets/icons/plus.svg";
import minus from "../../assets/icons/minus.svg";
import { CircleButtonContainer } from "../circleButtonContainer/circleButtonContainer";

export const CalcButton = ({
  onClick,
  className,
  disabled = false,
  withBlur = false,
  type = "plus",
  size = "30px",
}: {
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  withBlur?: boolean;
  type?: "plus" | "minus";
  size?: "30px" | "40px";
}) => {
  const icons = {
    plus: plus,
    minus: minus,
  };

  return (
    <CircleButtonContainer
      onClick={onClick}
      className={className}
      disabled={disabled}
      withBlur={withBlur}
      size={size}
      color={"calc"}
    >
      <img src={icons[type]} />
    </CircleButtonContainer>
  );
};
