import styles from "./modalContacts.module.scss";
import { Button } from "../button/button";
import {
  ModalComponent,
  ModalContentContainer,
} from "../modalComponent/modalComponent";
import phone from "../../assets/icons/phone.svg";
import mail from "../../assets/icons/mail.svg";
import flag from "../../assets/icons/flag.svg";
import { ModalComponentMobile } from "../modalComponentMobile/modalComponentMobile";
import { CopyButton } from "../copyButton/copyButton";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonModalWrapper } from "../commonModalWrapper/commonModalWrapper";
import { LazyImage } from "../slider/LazyImage";

const Icons = {
  phone,
  mail,
  flag,
};

export const ModalContacts = ({
  className,
  onButtonClick,
  onClose,
  isOpen,
  content,
}: {
  className?: string;
  onButtonClick?: () => void;
  onClose?: () => void;
  isOpen: boolean;
  content: any;
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <CommonModalWrapper onClose={onClose} isOpen={isOpen}>
      <ModalComponent
        buttonTitle={content.title}
        onButtonClick={onButtonClick}
        onClose={onClose}
        className={styles.modal}
      >
        <CopyButton
          title={content.contacts.title}
          copyData={content.contacts.items}
        />
        {content.contacts.items.map((it: any, index: number) => (
          <Item
            // @ts-ignore
            icon={Icons[it.icon]}
            info={it.info}
            title={it.title}
            first={index === 0}
            className={[
              styles.contactItem,
              index === 0 ? styles.itemContainer__first : "",
            ].join(" ")}
          />
        ))}
        <div className={styles.buttonContainer}>
          <Button
            className={[styles.button, styles.button__left].join(" ")}
            title="Оставить обращение"
            onClick={() =>
              navigate("/appeal", {
                state: { previousLocation: location },
              })
            }
          />
          <Button
            className={styles.button}
            title="Стать партнёром"
            color="white"
            onClick={() =>
              navigate("/partnership", {
                state: { previousLocation: location },
              })
            }
          />
        </div>
      </ModalComponent>
    </CommonModalWrapper>
  );
};

const Item = ({
  title,
  info,
  icon,
  className,
  first = false,
}: {
  title: string;
  info: string;
  icon: any;
  className?: string;
  first?: boolean;
}) => {
  return (
    <ModalContentContainer first={first} className={className}>
      <div className={[styles.itemContainer].join(" ")}>
        <LazyImage className={styles.itemIcon} image={icon} />
        <p className={styles.itemTitle}>{title}</p>
      </div>
      <p className={styles.itemInfo}>{info}</p>
    </ModalContentContainer>
  );
};

export const ModalContactsMobile = ({
  className,
  onButtonClick,
  onClose,
  content,
}: {
  className?: string;
  onButtonClick?: () => void;
  onClose?: () => void;
  content: any;
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <ModalComponentMobile
      buttonTitle={content.title}
      className={[styles.modalMobile, className].join(" ")}
      onButtonClick={onButtonClick}
      onClose={onClose}
    >
      <div className={styles.itemScrollContainer}>
        <CopyButton
          title={content.contacts.title}
          copyData={content.contacts.items}
        />
        {content.contacts.items.map((it: any, index: number) => (
          <Item
            first={index === 0}
            // @ts-ignore
            icon={Icons[it.icon]}
            info={it.info}
            title={it.title}
            className={[
              styles.contactItem,
              index === 0 ? styles.itemContainer__first : "",
            ].join(" ")}
          />
        ))}
      </div>

      <div
        className={[
          styles.buttonContainer,
          styles.buttonContainer__mobile,
        ].join(" ")}
      >
        <Button
          className={[styles.button_mobile, styles.button__top].join(" ")}
          title="Оставить обращение"
          color="black"
          size="big"
          onClick={() =>
            navigate("/appeal", {
              state: { previousLocation: location },
            })
          }
        />
        <Button
          className={styles.button_mobile}
          title="Стать партнёром"
          color="blackStroke"
          size="big"
          onClick={() =>
            navigate("/partnership", {
              state: { previousLocation: location },
            })
          }
        />
      </div>
    </ModalComponentMobile>
  );
};
