import styles from "./modalDetails.module.scss";
import { Button } from "../button/button";
import {
  ModalComponent,
  ModalContentContainer,
} from "../modalComponent/modalComponent";
import { CopyButton } from "../copyButton/copyButton";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import DownloadIcon1 from "../../assets/icons/download.svg";
import { replaceLinks } from "../../utils/replaceLinks";
import { CommonModalWrapper } from "../commonModalWrapper/commonModalWrapper";

export const ModalDetails = ({
  className,
  onButtonClick,
  onClose,
  isOpen,
  content,
}: {
  className?: string;
  onButtonClick?: () => void;
  onClose?: () => void;
  isOpen: boolean;
  content: any;
}) => {
  return (
    <CommonModalWrapper isOpen={isOpen} onClose={onClose}>
      <ModalComponent
        buttonTitle={content.title}
        onButtonClick={onButtonClick}
        onClose={onClose}
        className={styles.modal}
        headerButton={
          <Button
            url={content.downloadurl[0].url}
            RightIcon={
              // <div className={styles.headerButton__icon}>
              //   <DownloadIcon />
              // </div>
              <img src={DownloadIcon1} className={styles.headerButton__icon} />
            }
            title={"Скачать"}
            className={styles.headerButton}
          />
        }
        scrollable
      >
        <CopyButton title={content.main.title} copyData={content.main.items} />
        {content.main.items.map((it: any, index: number) => (
          <Item
            {...it}
            className={index === 0 ? styles.itemContainer__first : ""}
          />
        ))}
        <CopyButton
          className={styles.bankButton}
          title={content.bank.title}
          copyData={content.bank.items}
        />
        {content.bank.items.map((it: any, index: number) => (
          <Item
            {...it}
            className={index === 0 ? styles.itemContainer__first : ""}
          />
        ))}
      </ModalComponent>
    </CommonModalWrapper>
  );
};

const Item = ({
  title,
  info,
  className,
}: {
  title: string;
  info?: string;
  className?: string;
}) => {
  const content = replaceLinks(info || "");
  return (
    <ModalContentContainer
      className={[styles.itemContainer, className].join(" ")}
    >
      <p className={styles.itemTitle}>{title}</p>
      <p
        className={styles.itemInfo}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </ModalContentContainer>
  );
};
