import styles from "./modalAppealMobile.module.scss";
import { TextInput } from "../../textInput/textInput";
import { Button } from "../../button/button";
import { CommonModalGreenMobile } from "../commonModalGreenMobile/commonModalGreenMobile";
import { useEffect, useState } from "react";
import { Tabs } from "../../tabs/tabs";
import { DropdownPicker } from "../../dropdownPicker/dropdownPicker";
import { useLocation, useNavigate } from "react-router-dom";
import { APPEAL_APPLICATION_SCHEMA, APPEAL_APPLICATION_SCHEMA_TOPIC, useForm, } from "../../../utils/applications";
import { useRequest } from "../../../utils/api";
import { appealTopics } from "../../../data/appeals";

export const ModalAppealMobile = ( { onClose }: { onClose?: () => void } ) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [ tab, setTab ] = useState( 0 );

  const [ disableButton, setDisableButton ] = useState<boolean>( false );

  const [ buttonTitle, setButtonTitle ] = useState<string>( "Оставить обращение" );

  const { data, errors, setters, isValidationPassed } = useForm(
    tab === 2 ? APPEAL_APPLICATION_SCHEMA_TOPIC : APPEAL_APPLICATION_SCHEMA
  );

  const [ file, setFile ] = useState<any>( null );

  const [ fileError, setFileError ] = useState<boolean>( false );

  const { applyRequest, response, error } = useRequest();

  useEffect( () => {
    if ( response?.status === 200 ) {
      setButtonTitle( "Отправлено!" );
      setDisableButton( true );
    } else {
      setButtonTitle( "Оставить обращение" );
      setDisableButton( false );
    }
  }, [ response, error ] );

  const inputError =
    !!errors.name ||
    !!errors.phone ||
    ( ( !!errors.topic || fileError ) && tab === 2 );

  const onApply = async () => {
    if ( file === null ) {
      setFileError( true );
    } else {
      setFileError( false );
    }
    if ( isValidationPassed() && !( ( fileError || file === null ) && tab === 2 ) ) {
      setDisableButton( true );
      setButtonTitle( "Отправка..." );
      if ( tab === 0 ) {
        applyRequest(
          {
            name: data.name,
            phone: data.phone,
            inn: data.company,
            comment: data.comment,
          },
          "quality-service"
        );
      } else if ( tab === 1 ) {
        applyRequest(
          {
            name: data.name,
            phone: data.phone,
            mediaName: data.media,
            comment: data.comment,
          },
          "media"
        );
      } else if ( tab === 2 ) {
        applyRequest(
          {
            name: data.name,
            phone: data.phone,
            inn: data.company,
            requestType: data.topic,
            comment: data.comment,
          },
          "maintenance",
          file
        );
      }
    }
  };

  const terms = [
    "Нажимая на кнопку «Отправить», я соглашаюсь с условиями обработки моих персональных данных.",
    "При направлении заявки, не забудьте подписать бумажную версию документа и прикрепить скан.\nФормы заявлений можно взять в разделе «Документы».",
  ];

  const topics = appealTopics;

  return (
    <CommonModalGreenMobile
      buttonTitle={ "Оставить обращение" }
      onClose={ onClose }
      buttonClassName={ styles.topTitle }
      scrollContainerClassName={ styles.scrollContainer }
      zeroPadding
    >
      <Tabs
        dark
        onSelectTab={ setTab }
        selectedIdx={ tab }
        tabs={ [ "Служба качества", "Пресса", "Сопровождение" ] }
        containerClassName={ styles.tabs }
      />
      { tab === 2 && (
        <DropdownPicker
          value={ data.topic }
          setValue={ setters.topic }
          options={ topics }
          title={ "Выберите тему обращения" }
          className={ styles.picker }
          error={ errors.topic }
        />
      ) }
      <div className={ styles.container }>
        <TextInput
          value={ data.name }
          onChange={ setters.name }
          dark
          placeholder={ "Имя*" }
          error={ errors.name }
          type={ "name" }
        />
        <TextInput
          value={ data.phone }
          onChange={ setters.phone }
          dark
          placeholder={ "Телефон*" }
          error={ errors.phone }
          mask="+7 (999) 999-99-99"
          type={ "phone" }
        />

        { tab === 1 ? (
          <TextInput
            value={ data.media }
            onChange={ setters.media }
            dark
            placeholder={ "Название СМИ" }
            error={ errors.media }
          />
        ) : (
          <TextInput
            value={ data.company }
            onChange={ setters.company }
            dark
            placeholder={ "ИНН организации" }
            type={ "decimals" }
            error={ errors.company }
            maskChar=""
            mask={ "999999999999" }
          />
        ) }
        { tab === 1 ? (
          <TextInput
            value={ data.appeal }
            onChange={ setters.appeal }
            dark
            placeholder={ "Обращение" }
            className={ styles.las }
            error={ errors.appeal }
          />
        ) : (
          <TextInput
            value={ data.comment }
            onChange={ setters.comment }
            dark
            placeholder={ "Комментарий" }
            error={ errors.comment }
          />
        ) }
        <div
          className={ [
            styles.terms,
            styles.terms__input,
            ( inputError && styles.error ) || "",
          ].join( " " ) }
        >
          * — обязательное поле для заполнения
        </div>
        <div
          onClick={ () =>
            navigate( "/privacy-policy", {
              state: { previousLocation: location },
            } )
          }
          className={ styles.terms }
        >
          { terms[ tab === 2 ? 1 : 0 ] }
        </div>
        { tab === 2 && (
          <Button
            className={ [
              styles.button,
              styles.button__upper,
              ( inputError && styles.button__error ) || "",
            ].join( " " ) }
            title={
              file?.name?.length > 20
                ? file?.name?.slice( 0, 20 ) + "..."
                : file?.name || "Прикрепить документ*"
            }
            color="blackStroke"
            size="big"
            file
            onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => {
              setFile( ( e.target?.files && e.target?.files[ 0 ] ) || "" );
              setFileError( false );
            } }
          />
        ) }
        <Button
          title={ buttonTitle }
          size={ "big" }
          className={ styles.button }
          color={ "black" }
          onClick={ onApply }
          disabled={ disableButton }
        />
      </div>
    </CommonModalGreenMobile>
  );
};
