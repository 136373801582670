import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./userCard.module.scss";
import TeamAvatar1 from "../../assets/images/team_member1.png";
import LogoPlain from "../../assets/icons/logo_plain.svg";
import { LazyImage } from "../slider/LazyImage";

export const UserCard = ({
  user,
  className,
}: {
  user: { name: string; duty: string; avatar: string };
  className?: string;
}) => {
  const dutyRef = useRef<HTMLDivElement>(null);
  const [offsetY, setOffsetY] = useState(0);
  useEffect(() => {
    if (dutyRef.current) {
      const intervalId = setInterval(() => {
        const height = dutyRef.current?.getBoundingClientRect().height || 0;
        if (height > 18) {
          setOffsetY(height - 22);
          clearInterval(intervalId);
        }
      }, 100);
    }
    return () => {
      setOffsetY(0);
    };
  }, [dutyRef]);
  return (
    <div className={[styles.container, className].join(" ")}>
      <LazyImage image={user.avatar} className={styles.avatar} />
      <div className={styles.avatarOverlay} />

      <div className={styles.content}>
        <img className={styles.logo} src={LogoPlain} />
        <div className={styles.textContainer}>
          <div
            className={styles.name}
            style={
              offsetY
                ? {
                    transform: `translateY(${offsetY}px)`,
                  }
                : undefined
            }
          >
            {user.name}
          </div>
          <div className={styles.duty} ref={dutyRef}>
            {user.duty}
          </div>
        </div>
      </div>
    </div>
  );
};
