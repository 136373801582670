import React, { useState } from "react";
import styles from "./appeal.module.scss";
import { CommonModalMobileWrapper } from "../../components/commonModalMobileWrapper/commonModalMobileWrapper";
import { useIsMobile } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { ModalAppealMobile } from "../../components/modals/modalAppealMobile/modalAppealMobile";
import { ModalApplicationWithTabs } from "../../components/modals/modalApplicationWithTabs/modalApplicationWithTabs";

export const AppealPage = () => {
  const isMobile = useIsMobile();
  return isMobile ? <AppealMobile /> : <AppealDesktop />;
};

export const AppealMobile = () => {
  const navigate = useNavigate();
  return (
    <CommonModalMobileWrapper
      onClose={() => {
        navigate(-1);
        document.body.style.overflow = "unset";
      }}
      isOpen
    >
      <ModalAppealMobile
        onClose={() => {
          navigate(-1);
          document.body.style.overflow = "unset";
        }}
      />
    </CommonModalMobileWrapper>
  );
};

export const AppealDesktop = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ModalApplicationWithTabs
        isOpen
        onClose={() => {
          navigate(-1);
          document.body.style.overflow = "unset";
        }}
      />
    </div>
  );
};
