import React, {PropsWithChildren, ReactNode, useCallback, useLayoutEffect, useRef} from "react";
import styles from "./desktopBackground.module.scss";
import {getScrollProgress} from "../../utils/useCurrentPageIdx";
import {useEventListener} from "usehooks-ts";

const revealAndHideAnimation = (
  progress: number,
  start_index: number,
  end_index: number
) => {
  if(progress >= end_index) return 1
  if(progress <= start_index - 1) return 0
  const floored = Math.floor(progress)

  return floored === start_index - 1
    ? progress % 1
    : floored === end_index
      ? 1 - (progress % 1)
      : 1;
};

export const DesktopBackground = ({
  renderBackgroundComponent,
  withTopOverlay = true,
  shortOverlay = false,
}: {
  withTopOverlay?: boolean;
  shortOverlay?: boolean;
  renderBackgroundComponent: ({
    isOverlay,
  }: {
    isOverlay: boolean;
    register: (id: string, startIndex: number, endIndex: number) => (el: HTMLElement | null) => void
  }) => ReactNode;
}) => {
  const backgroundImages = useRef<Record<string, HTMLElement | null>>({});
  const overlayImages = useRef<Record<string, HTMLElement | null>>({});
  const progressValues = useRef<Record<string, [number, number]>>({});

  const registerBackground = useCallback((id: string, startIndex: number, endIndex: number) => {
    progressValues.current[id] = [startIndex, endIndex]
    return (el: HTMLElement | null) => {
      backgroundImages.current[id] = el;
      if(!el) return
      const scrollProgress = getScrollProgress()
      el.style.opacity = revealAndHideAnimation(
        scrollProgress,
        startIndex,
        endIndex,
      ).toString();
    }
  }, [])
  const registerOverlay = useCallback((id: string, startIndex: number, endIndex: number) => {
    return (el: HTMLElement | null) => {
      overlayImages.current[id] = el;
      if(!el) return
      const scrollProgress = getScrollProgress()
      el.style.opacity = revealAndHideAnimation(
        scrollProgress,
        startIndex,
        endIndex,
      ).toString();
    }
  }, [])

  const scrollFn = useCallback(() => {
    const scrollProgress = getScrollProgress()
    for(const [id, [startIndex, endIndex]] of Object.entries(progressValues.current)) {
      const opacity = revealAndHideAnimation(
        scrollProgress,
        startIndex,
        endIndex,
      ).toString();
      [backgroundImages.current[id], overlayImages.current[id]].forEach((it) => {
        if (it) {
          it.style.opacity = opacity;
        }
      })
    }
  }, [progressValues, backgroundImages, overlayImages])

  useLayoutEffect(scrollFn, [scrollFn])
  useEventListener("scroll", scrollFn, undefined, {passive: true});

  return (
    <div className={styles.backgroundWrapper}>
      <div className={`${styles.backgroundContainer}`}>
        {renderBackgroundComponent({ isOverlay: false, register: registerBackground })}
      </div>
      {withTopOverlay && (
        <div
          className={`${styles.backgroundContainer} ${styles.overlay} ${
            shortOverlay ? styles.overlay_short : ""
          }`}
        >
          {renderBackgroundComponent({ isOverlay: true, register: registerOverlay })}
        </div>
      )}
    </div>
  );
};
