import React, { useEffect } from 'react';

interface IProps {
  title?: string;
  description?: string;
}

const MetaData = ( { title = '', description = '' }: IProps ) => {
  useEffect( () => {
    document.title = title;
    document.querySelector( 'meta[name="description"]' )?.setAttribute( "content", description );
    let t = document.querySelector( 'title' );
    if ( t ) t.innerHTML = title;
  }, [ title, description ] )

  return (
    <></>
  );
};

export default MetaData;
