import { CSSProperties, ReactNode, useRef } from "react";
import styles from "./button.module.scss";
import { NavLink } from "react-router-dom";

export const Button = ({
  style,
  color = "green",
  disabled = false,
  title,
  size = "medium",
  className,
  onClick,
  onChange,
  changeSizeIfMobile = false,
  RightIcon,
  url,
  to,
  file = false,
}: {
  style?: CSSProperties;
  color?:
    | "light"
    | "black"
    | "green"
    | "gray"
    | "grayFilled"
    | "white"
    | "grayBlurred"
    | "blackStroke"
    | "greenInverted";
  disabled?: boolean;
  title: string;
  size?: "small" | "medium" | "big" | "average";
  className?: string;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changeSizeIfMobile?: boolean;
  RightIcon?: ReactNode;
  url?: string;
  to?: string;
  file?: boolean;
}) => {
  const props = {
    onClick,
    style,
    className: [
      styles.container,
      styles[`container__${color}`],
      styles[`container__${size}`],
      changeSizeIfMobile && styles.container__mobile,
      (disabled && styles.container__disabled) || "",
      className || "",
    ].join(" "),
  };

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  if (to) {
    return (
      <NavLink {...props} to={to}>
        {RightIcon}
        {title}
      </NavLink>
    );
  }

  if (url) {
    return (
      <a {...props} href={url} target="_blank" download={url.split("/").pop()}>
        {RightIcon}
        {title}
      </a>
    );
  }

  if (file) {
    const handleClick = () => {
      if (hiddenFileInput.current) {
        hiddenFileInput.current?.click();
      }
    };
    return (
      <div {...props} onClick={handleClick}>
        <input
          type={"file"}
          ref={hiddenFileInput}
          onChange={onChange}
          style={{ display: "none" }}
        />
        {RightIcon}
        {title}
      </div>
    );
  }

  return (
    <div {...props} onClick={disabled ? () => {} : onClick}>
      {RightIcon}
      {title}
    </div>
  );
};
