import { CSSProperties, useMemo } from "react";
import styles from "./listItem.module.scss";
import { LazyImage } from "../slider/LazyImage";

export const ListItem = ({
  title,
  description,
  titleTextStyle,
  descriptionTextStyle,
  icon,
  className,
  program = false,
  option = false,
}: {
  title: string;
  description?: string | Array<string>;
  titleTextStyle?: CSSProperties;
  descriptionTextStyle?: CSSProperties;
  icon: any;
  className?: string;
  program?: boolean;
  option?: boolean;
}) => {
  return (
    <div
      className={[
        styles.container,
        description ? styles.container__double : "",
        program ? styles.container__program : "",
        className || "",
      ].join(" ")}
    >
      <div
        className={[
          styles.iconContainer,
          description ? styles.iconContainer__double : "",
        ].join(" ")}
      >
        <LazyImage className={styles.icon} image={icon} />
      </div>
      <div>
        <p
          className={[
            styles.titleText,
            description ? styles.titleText__double : "",
            program ? styles.titleText__program : "",
          ].join(" ")}
          style={titleTextStyle}
        >
          {title}
        </p>
        {typeof description === "object" ? (
          description.map((it) => (
            <p
              className={[
                styles.descriptionText,
                program ? styles.descriptionText__program : "",
                option ? styles.descriptionText__option : "",
              ].join(" ")}
              style={descriptionTextStyle}
            >
              {it}
            </p>
          ))
        ) : (
          <p
            className={[
              styles.descriptionText,
              program ? styles.descriptionText__program : "",
              option ? styles.descriptionText__option : "",
            ].join(" ")}
            style={descriptionTextStyle}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
};
