import React, { useState } from "react";
import styles from "./privacyPolicy.module.scss";
import { CommonModalMobileWrapper } from "../../components/commonModalMobileWrapper/commonModalMobileWrapper";
import { ModalPrivacyPolicyMobile } from "../../components/modals/modalPrivacyPolicyMobile/modalPrivacyPolicyMobile";
import { ModalPrivacyPolicy } from "../../components/modals/modalPrivacyPolicy/modalPrivacyPolicy";
import { useIsMobile } from "../../utils/common";
import { useLocation, useNavigate } from "react-router-dom";
import { mockPrivacyPolicyModalData } from "./mockData";

export const PrivacyPolicyPage = () => {
  const isMobile = useIsMobile();

  return isMobile ? <PrivacyPolicyMobile /> : <PrivacyPolicyDesktop />;
};

export const PrivacyPolicyMobile = () => {
  const navigate = useNavigate();
  return (
    <CommonModalMobileWrapper
      onClose={() => {
        navigate(-1);
        document.body.style.overflow = "unset";
      }}
      isOpen
    >
      <ModalPrivacyPolicyMobile
        content={mockPrivacyPolicyModalData}
        onClose={() => {
          navigate(-1);
          document.body.style.overflow = "unset";
        }}
      />
    </CommonModalMobileWrapper>
  );
};

export const PrivacyPolicyDesktop = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ModalPrivacyPolicy
        content={mockPrivacyPolicyModalData}
        onClose={() => {
          navigate(-1);
          document.body.style.overflow = "unset";
        }}
        isOpen
      />
    </div>
  );
};
