import styles from "./arrowButton.module.scss";
import arrowBlack from "../../assets/icons/arrow-black.svg";
import arrowWhite from "../../assets/icons/arrow-white.svg";
import { CircleButtonContainer } from "../circleButtonContainer/circleButtonContainer";

export const ArrowButton = ({
  color,
  direction,
  onClick,
  className,
  disabled = false,
  withBlur = false,
}: {
  onClick?: () => void;
  className?: string;
  color: "white" | "black";
  direction?: "up" | "down" | "left" | "right";
  disabled?: boolean;
  withBlur?: boolean;
}) => {
  const arrowDirection = {
    left: "",
    right: styles.arrow_right,
    up: styles.arrow_up,
    down: styles.arrow_down,
  };
  const arrowColor = {
    black: arrowBlack,
    white: arrowWhite,
  };
  return (
    <CircleButtonContainer
      onClick={onClick}
      className={className}
      color={color}
      disabled={disabled}
      withBlur={withBlur}
      size={"40px"}
    >
      <img
        src={arrowColor[color]}
        className={[arrowDirection[direction || "left"]].join(" ")}
      />
    </CircleButtonContainer>
  );
};
