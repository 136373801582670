import { useState } from "react";
import { CommonModalGreen } from "../commonModalGreen/commonModalGreen";
import styles from "./modalPrivacyPolicy.module.scss";
import { CollapsibleParagraph } from "../../collapsibleParagraph/collapsibleParagraph";

interface TermType {
  title: string;
  description: string;
}

export interface ModalPrivacyPolicyType {
  title: string;
  description: string;
  terms: TermType[];
}

export const ModalPrivacyPolicy = ({
  isOpen,
  onClose,
  content,
}: {
  isOpen: boolean;
  onClose?: () => void;
  content: ModalPrivacyPolicyType;
}) => {
  return (
    <CommonModalGreen
      onClose={onClose}
      isOpen={isOpen}
      title={content.title}
      modalClassName={styles.modal}
      titleClassName={styles.title}
    >
      <div className={styles.description}>{content.description}</div>
      {content.terms.map((it) => (
        <CollapsibleParagraph title={it.title} children={it.description} />
      ))}
    </CommonModalGreen>
  );
};
