import React, { createContext, PropsWithChildren, useEffect, useMemo, useRef, useState, } from "react";
import { useCurrentPageIdx } from "../../utils/useCurrentPageIdx";
import { _getTabTransform, useTabScroller } from "../../utils/useTabScroller";
import { useNavigate } from "react-router-dom";
import styles from "./template.module.scss";
import { Header } from "../../components/header/header";
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs";
import { RightButtons } from "../../components/rightButtons/rightButtons";
import { ProgressBar } from "../../components/progressBar/progressBar";
import { Tab, Tabs } from "../../components/tabs/tabs";
import { handleBlockScroll, useIsMobile } from "../../utils/common";
import { DesktopBackground } from "../../components/desktopBackground/desktopBackground";
import { blockHandler, extractBackgrounds, extractModals, modalHandler, pageNameHandler, } from "./blockHandler";
import { HeaderMobile } from "../../components/headerMobile/headerMobile";
import { ReactComponent as WhiteArrow } from "../../assets/icons/white_arrow.svg";
import { ReactComponent as GreenArrow } from "../../assets/icons/arrow_green.svg";
import { CardTransitionWrapper } from "../../components/cardTransitionWrapper/cardTransitionWrapper";
import { BottomTabs } from "../../components/bottomTabs/bottomTabs";
import { CommonModalMobileWrapper } from "../../components/commonModalMobileWrapper/commonModalMobileWrapper";
import { Button } from "../../components/button/button";
import phone from "../../assets/icons/phone_white_main.svg";
import { MenuType, ObjectNumber, SearchType, } from "../program/types/blockTypes";
import { LazyImage, LazyImageSwitcher, } from "../../components/slider/LazyImage";
import MetaData from "../../components/MetaData";
import Error404 from "./error404";

export const DesktopBlocks = ( { children }: any ) => (
  <div className={ styles.blockContainer }>{ children }</div>
);

interface CalculatorContextProps {
  calculation: any;
  setCalculation: ( values: any ) => void;
}

export const CalculatorContext = createContext<CalculatorContextProps>(
  {} as CalculatorContextProps
);

export const PageTemplate = ( { pageData, menuData, searchData }: any ) => {
  const isMobile = useIsMobile();
  const modals = useMemo( () => extractModals( pageData ), [ pageData ] );
  const { desktopbackgrounds, mobilebackgrounds } = useMemo(
    () => extractBackgrounds( pageData ),
    [ pageData ]
  );
  const pageNames = useMemo(
    () =>
      pageData.blocks
        .slice( 1 )
        .map(
          ( _block: any ) => _block.breadcrumb || pageNameHandler( _block.type )
        ),
    [ pageData ]
  );

  const [ calculation, setCalculation ] = useState( null );

  return (
    <CalculatorContext.Provider value={ { calculation, setCalculation } }>
      <MetaData title={ pageData.seotitle } description={ pageData.seodescription }/>
      { isMobile ? (
        <PageTemplateMobile
          pageData={ pageData }
          modals={ modals }
          pageNames={ pageNames }
          backgrounds={ mobilebackgrounds }
          menuOptions={ menuData }
          search={ searchData }
        />
      ) : (
        <PageTemplateDesktop
          pageData={ pageData }
          modals={ modals }
          pageNames={ pageNames }
          backgrounds={ desktopbackgrounds }
          menuOptions={ menuData }
          search={ searchData }
        />
      ) }
    </CalculatorContext.Provider>
  );
};

const TabContainer = ( props: PropsWithChildren ) => {
  const tabContainer = useRef<HTMLDivElement>( null );
  useTabScroller( tabContainer );
  return (
    <div
      ref={ tabContainer }
      className={ styles.tabsContainer }
      style={ {
        transform: _getTabTransform(),
      } }
      data-tabs="true"
    >
      { props.children }
    </div>
  );
};

export const PageTemplateDesktop = (
  {
    pageData,
    modals,
    backgrounds,
    pageNames,
    menuOptions,
    search,
  }: {
    pageData: any;
    modals: any;
    backgrounds: any;
    pageNames: string[];
    menuOptions: MenuType;
    search: SearchType;
  } ) => {
  const currentPageIdx = useCurrentPageIdx();

  const scrollToTop = () => document.documentElement.scrollTo( { top: 0 } );

  const navigate = useNavigate();

  const [ currentOpenModal, openModal ] = useState<string | null>( null );
  const [ addCalculation, setAddCalculation ] = useState<boolean>( false );
  const onModalClose = () => {
    openModal( null );
    setAddCalculation( false );
  };
  const blackIds =
    pageData?.modifiers?.darkbackgroundidx?.map(
      ( it: ObjectNumber ) => it?.value
    ) || [];

  const dynamicBackgrounds = useRef<any>( {} );
  const changeBackground = ( key: string, slide: number ) => {
    for ( const fn of dynamicBackgrounds.current[ key ]?.values() || [] ) {
      fn( slide );
    }
  };
  return (
    <div className={ styles.pageWrapper }>
      <Header
        content={ menuOptions }
        TextComponent={
          <Breadcrumbs
            path={ [
              ...( pageData.breadcrumbs || [] ).map( ( it: any ) => ( {
                title: it.title,
                onClick: () => navigate( it.to ),
              } ) ),
              {
                title: pageData.title,
                onClick: scrollToTop,
              },
            ] }
            currentPage={ currentPageIdx }
            pageNames={ pageNames }
            blackIndexes={ blackIds }
          />
        }
        pageCounter={ ( currentPageIdx || 1 ) + 1 }
        isBlackInner={ blackIds.indexOf( currentPageIdx ) !== -1 }
      />

      <RightButtons searchData={ search }>
        { pageData.menulinks?.map( ( { title, to }: any ) => (
          <Button
            title={ title }
            onClick={ () => {
              // TODO: Extract to common useEffect
              if ( to.startsWith( "#modal_" ) ) {
                openModal( to.slice( "#modal_".length ) );
              } else {
                navigate( to );
              }
            } }
            color={ "grayBlurred" }
            size={ "average" }
          />
        ) ) }
      </RightButtons>

      <ProgressBar
        blackCountScreens={ blackIds }
        pageCount={ pageData.blocks.length }
      />

      { pageData.tabs ? (
        <TabContainer>
          <Tabs
            selectedIdx={ currentPageIdx - 1 }
            onSelectTab={ ( _idx ) => handleBlockScroll( _idx + 1 ) }
            tabs={ pageNames }
          />
        </TabContainer>
      ) : null }

      { pageData.hoverlink && (
        <div className={ styles.phoneContainer }>
          <img className={ styles.icon } src={ phone }/>
          <a className={ styles.phone } href={ pageData.hoverlinkto }>
            { pageData.hoverlink }
          </a>
        </div>
      ) }

      <DesktopBackground
        shortOverlay={ !pageData.tabs }
        withTopOverlay
        renderBackgroundComponent={ ( { register } ) =>
          backgrounds.map(
            ( { start, end, src, srcs, key, classname }: any, index: number ) => {
              const ref = register(
                index.toString(),
                start,
                typeof end === "number" ? end : pageData.blocks.length
              );
              if ( srcs ) {
                return (
                  <LazyImageSwitcher
                    images={ srcs }
                    ref={ ref }
                    addContainer
                    containerClassname={ classname ? styles[ classname ] : "" }
                    imageClassname={ styles.backgroundImage_slide }
                    showClassname={ styles.backgroundImage_slide_show }
                    register={ ( fn ) => {
                      ( dynamicBackgrounds.current[ key ] ||= new Set() ).add( fn );
                    } }
                    unregister={ ( fn ) => {
                      dynamicBackgrounds.current[ key ].delete( fn );
                    } }
                  />
                );
              }

              if ( !classname ) {
                return (
                  <LazyImage
                    image={ src }
                    ref={ ref }
                    className={ styles.backgroundImage }
                    key={ pageData.route + start + "_" + end }
                  />
                );
              }

              return (
                <div
                  style={ { willChange: "opacity" } }
                  className={ classname ? styles[ classname ] : "" }
                  ref={ ref }
                  key={ pageData.route + start + "_" + end + "div" }
                >
                  <LazyImage image={ src } className={ styles.backgroundImage }/>
                </div>
              );
            }
          )
        }
      />

      <DesktopBlocks>
        { pageData.route === '*' &&
            <Error404/>
        }
        { pageData.blocks.map( ( _block: any, index: number ) => (
          <>
            <a id={ _block.key } key={ `a_${ _block.key }` } data-key={ _block.key }/>
            { blockHandler( _block, index - 1, {
              openModal,
              setAddCalculation,
              changeBackground,
            } ) }
          </>
        ) ) }
      </DesktopBlocks>

      { Object.values( modals ).map( ( data ) =>
        modalHandler( data, {
          currentOpenModal,
          onModalClose,
          addCalculation,
        } )
      ) }
    </div>
  );
};
export const PageTemplateMobile = (
  {
    pageData,
    modals,
    backgrounds,
    pageNames,
    menuOptions,
    search,
  }: {
    pageData: any;
    modals: any;
    backgrounds: any;
    pageNames: string[];
    menuOptions: MenuType;
    search: SearchType;
  } ) => {
  const cardWrapperRef = useRef( null );
  const [ currentPage, setCurrentPage ] = useState( 0 );
  const navigate = useNavigate();

  const [ currentOpenModal, openModal ] = useState<string | null>( null );
  const [ addCalculation, setAddCalculation ] = useState<boolean>( false );

  const onModalClose = () => {
    openModal( null );
    setAddCalculation( false );
  };

  const isMobile = useIsMobile();
  useEffect( () => {
    if ( isMobile ) {
      onModalClose();
    }

  }, [ isMobile ] );

  const dynamicBackgrounds = useRef<any>( {} );
  const changeBackground = ( key: string, slide: number ) => {
    for ( const fn of dynamicBackgrounds.current[ key ]?.values() || [] ) {
      fn( slide );
    }
  };
  const backbutton = pageData.backbutton?.[ 0 ];

  // TODO: Consider moving it into a React context
  const onHeightsChanged = () =>
    // @ts-ignore
    cardWrapperRef.current?.updateHeightContainer();

  const blocks = useMemo( () => {
    console.log( 'blocks memo run' )
    const _cards: {
      blocks: any;
      background: any;
      foreground: any;
      firstIndex: number;
    }[] = [];
    for ( let i = 0; i < pageData.blocks.length; i++ ) {
      const block = pageData.blocks[ i ];
      const background = backgrounds
        .filter( ( it: any ) => !it.foreground )
        .findLast( ( it: any ) => i >= it.start );
      const foreground = backgrounds
        .filter( ( it: any ) => it.foreground )
        .findLast( ( it: any ) => i >= it.start );
      // let card = background.merged ? _cards[_cards.length - 1] : {}
      let card;
      if (
        background.merged &&
        _cards.length > 0 &&
        _cards[ _cards.length - 1 ].background === background
      ) {
        card = _cards[ _cards.length - 1 ];
      } else {
        card = {
          blocks: [],
          background,
          foreground,
          firstIndex: i,
        };
        _cards.push( card );
      }
      card.blocks.push( block );
    }

    const _blocks = _cards.map( ( card: any, i: number ) => (
      <div
        className={
          i === 0 ? styles.mobileBlock_first : styles.mobileBlock_first
        }
      >
        { card.blocks.map( ( _block: any, index: number ) => {

            return (
              <>
                <a id={ _block.key } key={ `a_${ _block.key }` } data-key={ _block.key }/>
                { pageData.route === '*' &&
                    <Error404/>
                }
                { blockHandler( _block, card.firstIndex + index, {
                  openModal,
                  changeBackground,
                  onHeightsChanged,
                  mobile: true,
                  setAddCalculation,
                } ) }
              </>
            )
          }
        ) }

        { card.background.srcs ? (
          <LazyImageSwitcher
            addContainer={ false }
            register={ ( fn ) => {
              ( dynamicBackgrounds.current[ card.background.key ] ||=
                new Set() ).add( fn );
            } }
            unregister={ ( fn ) => {
              dynamicBackgrounds.current[ card.background.key ].delete( fn );
            } }
            images={ card.background.srcs }
            imageClassname={ styles.backgroundImage_slide }
            showClassname={ styles.backgroundImage_slide_show }
          />
        ) : (
          <LazyImage
            image={ card.background.src }
            className={ [
              styles.mobileBlock__background,
              card.background.classname
                ? styles[ card.background.classname ]
                : "",
            ].join( " " ) }
          />
        ) }
        { card.foreground &&
          ( card.foreground.srcs ? (
            <LazyImageSwitcher
              addContainer={ false }
              register={ ( fn ) => {
                ( dynamicBackgrounds.current[ card.foreground.key ] ||=
                  new Set() ).add( fn );
              } }
              unregister={ ( fn ) => {
                dynamicBackgrounds.current[ card.foreground.key ].delete( fn );
              } }
              images={ card.foreground.srcs }
              imageClassname={ styles.backgroundImage_slide }
              showClassname={ styles.backgroundImage_slide_show }
            />
          ) : (
            <LazyImage
              image={ card.foreground.src }
              className={ [
                styles.mobileBlock__background,
                card.foreground.classname
                  ? styles[ card.foreground.classname ]
                  : "",
              ].join( " " ) }
            />
          ) ) }
      </div>
    ) )
    return _blocks;
  }, [ pageData, backgrounds ] );

  return (
    <div className={ styles.mobileContainer }>
      <HeaderMobile
        search={ search }
        content={ menuOptions }
        ButtonContainer={
          backbutton || pageData.tabs || pageData.menulinks ? (
            <Tabs
              backbuttonComponent={
                backbutton ? (
                  <Tab
                    LeftIcon={
                      backbutton.style === "green" ? (
                        <GreenArrow className={ styles.tabbackbutton_icon }/>
                      ) : (
                        <WhiteArrow className={ styles.tabbackbutton_icon }/>
                      )
                    }
                    className={
                      styles[ `tabbackbutton_${ backbutton.style || "white" }` ]
                    }
                    title={ "Назад" }
                    onClick={ () => navigate( -1 ) }
                  />
                ) : null
              }
              menulinks={ pageData.menulinks?.map( ( { title, to }: any ) => (
                <Tab
                  selected={ to.startsWith( "#management" ) && currentPage === 4 }
                  onClick={ () => {
                    // TODO: Extract to common useEffect
                    if ( to.startsWith( "#modal_" ) ) {
                      openModal( to.slice( "#modal_".length ) );
                    } else if ( to.startsWith( "#management" ) ) {
                      // @ts-ignore fixme: bind hashes to scrollToIdxCard
                      cardWrapperRef.current?.scrollToIdxCard( 4 );
                    } else {
                      navigate( to );
                    }
                  } }
                  title={ title }
                />
              ) ) }
              tabs={ pageData.tabs ? pageNames : null }
              containerClassName={ styles.mobileTabsContainer }
              onSelectTab={ ( idx ) =>
                // @ts-ignore
                cardWrapperRef.current?.scrollToIdxCard( idx + 1 )
              }
              selectedIdx={ currentPage - 1 }
            />
          ) : null
        }
      />

      <CardTransitionWrapper
        ref={ cardWrapperRef }
        topCardPadding={
          backbutton || pageData.tabs || pageData.menulinks ? 124 : 76
        }
        onCardChange={ setCurrentPage }
        blocks={ blocks }
      />

      <BottomTabs content={ menuOptions }/>

      { Object.values( modals ).map( ( data: any ) => (
        <CommonModalMobileWrapper
          isOpen={ currentOpenModal === data.key }
          onClose={ onModalClose }
        >
          { modalHandler( data, {
            currentOpenModal,
            onModalClose,
            mobile: true,
            addCalculation,
          } ) }
        </CommonModalMobileWrapper>
      ) ) }
    </div>
  );
};
