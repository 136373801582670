import React, { useRef, useState } from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { FeaturesContainerMobile } from "../../../../components/featuresContainerMobile/featuresContainerMobile";
import { FeaturesContainer, } from "../../../../components/featuresContainer/featuresContainer";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { ProgramType } from "../../../options/blocks/combinations";
import { useLocation } from "react-router-dom";

interface ServicesPageProgramsBlockType {
  title?: string;
  programs: Array<ProgramType>;
  pageindicatoroverlaymobile?: boolean;
}

export const ServicesPageProgramsBlock = (
  {
    mobile,
    titleShown,
    blocksShown,
    content,
  }: DesktopBlockPropsType & { content: ServicesPageProgramsBlockType } ) => {
  const scrollRef = useRef<HTMLDivElement>( null );

  const [ scroll, setScroll ] = useState<number>( 0 );

  const location = useLocation();

  return (
    <CommonBlock fullWidth>
      <div className={ styles.screenMobile }>
        <FeaturesContainerMobile
          indicatorOverlay={ content.pageindicatoroverlaymobile }
          features={ content.programs }
        />
      </div>

      <div
        className={ [
          styles.screen,
          ( content.programs?.length > 4 && styles.screen__scroll ) || "",
        ].join( " " ) }
      >
        { content.title && (
          <AnimatedBlock
            isShown={ mobile || blocksShown }
            className={ styles.title }
            type={ location.pathname === '/' ? 'h1' : 'h2' }
          >
            { content.title }
          </AnimatedBlock>
        ) }

        <AnimatedBlock
          isShown={ mobile || blocksShown }
          className={ styles.programWrapper }
        >
          <FeaturesContainer
            onScroll={ ( e ) => {
              setScroll( e.currentTarget.scrollLeft );
            } }
            ref={ scrollRef }
            features={ content.programs }
          />
        </AnimatedBlock>

        {/* <SideGradient
          className={(hideButtonsAndBlur && styles.hide) || ""}
          hidden={true}
          side={"right"}
        /> */ }
      </div>
    </CommonBlock>
  );
};
