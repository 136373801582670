import React from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { FeatureCard } from "../../../../components/featureCard/featureCard";

import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";

interface CardType {
  icon: string;
  title: string;
}

interface AboutUsPageCardsBlockType {
  title: string;
  cards: CardType[];
}

export const AboutUsPageCardsBlock = ( {
                                         titleShown,
                                         blocksShown,
                                         mobile = false,
                                         content,
                                       }: DesktopBlockPropsType & { content: AboutUsPageCardsBlockType } ) => {
  return (
    <CommonBlock fullWidth>
      <div className={ styles.wrapper }>
        <div className={ styles.container }>
          <AnimatedBlock
            isShown={ mobile || titleShown }
            className={ styles.title }
            type='h2'
          >
            { content.title }
          </AnimatedBlock>
          <div className={ styles.cardContainer }>
            <div className={ styles.cardUpperContainer }>
              {
                // @ts-ignore
                [ ...Array( 4 ).keys() ].map( ( _key, index ) => (
                  <AnimatedBlock
                    className={ styles.card }
                    isShown={ mobile || blocksShown }
                    latency={ index === 0 || index === 1 ? 0 : 300 }
                  >
                    <FeatureCard
                      className={ styles.cardInner }
                      title={ content.cards[ index ].title }
                      icon={ content.cards[ index ].icon }
                    />
                  </AnimatedBlock>
                ) )
              }
            </div>

            <AnimatedBlock
              className={ styles.cardBottomContainer }
              latency={ 600 }
              isShown={ mobile || blocksShown }
            >
              {
                // @ts-ignore
                [ ...Array( 3 ).keys() ].map( ( _key, index ) => (
                  <FeatureCard
                    className={ [
                      styles.card,
                      index === 2 ? styles.card_last : "",
                    ].join( " " ) }
                    title={ content.cards[ index + 4 ].title }
                    icon={ content.cards[ index + 4 ].icon }
                  />
                ) )
              }
            </AnimatedBlock>
          </div>
        </div>
      </div>
    </CommonBlock>
  );
};
