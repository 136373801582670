import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./collapsibleParagraph.module.scss";
import arrowUp from "../../assets/icons/chevron-up_gray.svg";
import arrowDown from "../../assets/icons/chevron-down_gray.svg";

export const CollapsibleParagraph = ({
  children,
  onQuestionChange,
  title,
}: {
  children?: ReactNode;
  onQuestionChange?: () => void;
  title?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onQuestionChange && setTimeout(onQuestionChange, 300);
  }, [isOpen]);

  return (
    <div
      className={styles.collapsible}
      onClick={() => {
        setIsOpen((prevState) => !prevState);
      }}
    >
      <div className={styles.title}>{title}</div>
      <img
        className={[styles.closeButton].join(" ")}
        src={isOpen ? arrowUp : arrowDown}
      />
      <div
        ref={contentRef}
        className={styles.content}
        style={{
          height: isOpen ? contentRef.current?.scrollHeight || 0 : 0,
        }}
      >
        <div className={styles.content__text}>{children}</div>
      </div>
    </div>
  );
};
