import React, { useState } from "react";
import styles from "./option.module.scss";
import { CommonModalMobileWrapper } from "../../components/commonModalMobileWrapper/commonModalMobileWrapper";
import { useIsMobile } from "../../utils/common";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalOptionMobile } from "../../components/modals/modalOptionMobile/modalOptionMobile";
import { ModalOption } from "../../components/modals/modalOption/modalOption";

export const OptionModalPage = () => {
  const isMobile = useIsMobile();
  return isMobile ? <OptionMobile /> : <OptionDesktop />;
};

export const OptionMobile = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const content = location.state?.content;
  return (
    <CommonModalMobileWrapper onClose={() => navigate(-1)} isOpen>
      <ModalOptionMobile content={content} onClose={() => navigate(-1)} />
    </CommonModalMobileWrapper>
  );
};

export const OptionDesktop = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const content = location.state?.content;
  return (
    <div>
      <ModalOption content={content} isOpen onClose={() => navigate(-1)} />
    </div>
  );
};
