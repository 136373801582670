import styles from "./modalSpecialOrderMobile.module.scss";
import { CommonModalGreenMobile } from "../commonModalGreenMobile/commonModalGreenMobile";
import { PromoModalContent } from "../../../pages/program/types/blockTypes";

export const ModalSpecialOrderMobile = ({
  onClose,
  content,
}: {
  onClose?: () => void;
  content: PromoModalContent;
}) => {
  return (
    <CommonModalGreenMobile
      buttonTitle={"Акция"}
      title={content.title}
      onClose={onClose}
    >
      <p className={styles.content}>{content.description}</p>
      <div className={styles.bottomListContainer}>
        <p className={styles.heading}>{content.subtitle}</p>
        <ul className={[styles.content, styles.list].join(" ")}>
          {content.columntexts.map((it) => (
            <li>{it.value}</li>
          ))}
        </ul>
      </div>
      <p className={styles.smallText}>{content.disclaimer}</p>
    </CommonModalGreenMobile>
  );
};
