import React from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { ListItem } from "../../../../components/listItem/listItem";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";

interface ProgramPageAboutBlockContentType {
  aboutuslist: { item: string; value: string; icon: string }[];
}

export const ProgramPageAboutBlock = ({
  mobile = false,
  content,
  blocksShown,
}: DesktopBlockPropsType & { content: ProgramPageAboutBlockContentType }) => {
  return (
    <CommonBlock>
      <div className={styles.mainContainer}>
        {mobile ? (
          content.aboutuslist.map((it) => (
            <ListItem
              icon={it.icon}
              title={it.item}
              description={it.value}
              program
            />
          ))
        ) : (
          <>
            <AnimatedBlock
              isShown={mobile || blocksShown}
              className={styles.leftContainer}
            >
              {content.aboutuslist.map(
                (it, index) =>
                  index % 2 === 0 && (
                    <ListItem
                      icon={it.icon}
                      title={it.item}
                      description={it.value}
                      program
                    />
                  )
              )}
            </AnimatedBlock>
            <AnimatedBlock
              latency={300}
              isShown={mobile || blocksShown}
              className={styles.rightContainer}
            >
              {content.aboutuslist.map(
                (it, index) =>
                  index % 2 === 1 && (
                    <ListItem
                      icon={it.icon}
                      title={it.item}
                      description={it.value}
                      program
                    />
                  )
              )}
            </AnimatedBlock>
          </>
        )}
      </div>
    </CommonBlock>
  );
};
