import React from 'react';
import styles from "./template.module.scss";

const Error404 = () => {
  return (
    <div
      className={ styles.error404 }>
      404
    </div>
  );
};

export default Error404;
