import React, { useEffect, useState } from 'react';


interface IProps {
  duration: number;
  showIndex: number;
  children?: any;
  opacity?: number;
}

const AnimatedChange = ( { duration, children, showIndex, opacity }: IProps ) => {
  const [ show, setShow ] = useState( showIndex );

  useEffect( () => {
    if ( show != showIndex ) {
      setTimeout( () => {
        setShow( showIndex );
      }, duration * 1000 )
    }
  }, [ showIndex ] );

  const childrens = React.Children.toArray( children );

  return (
    <div style={ { position: "relative" } }>
      { //@ts-ignore
        React.cloneElement( childrens[ show ], {
          style: {
            position: 'relative',
            transition: "opacity " + duration + "s",
            opacity: show === showIndex ? ( opacity || 1 ) : 0
          }
        } ) }
      { show !== showIndex && (

        //@ts-ignore
        React.cloneElement( childrens[ showIndex ], {
          style: {
            top: 0,
            position: 'absolute',
            transition: "opacity " + duration + "s",
            opacity: opacity || 1
          }
        } ) ) }
    </div>
  );
};

export default AnimatedChange;
