import {DESKTOP_BREAKPOINT_WIDTH, MOBILE_BREAKPOINT_WIDTH,  useEventMemo} from "./common";
import {horizontalPadding} from "./common";

export const calcScale = (contentWidth: number) => {
  const {clientWidth} = document.documentElement;
  const doublePadding = horizontalPadding() * 2
  const targetWidth = Math.min(clientWidth - doublePadding, DESKTOP_BREAKPOINT_WIDTH);
  if(clientWidth >= MOBILE_BREAKPOINT_WIDTH) {
    return targetWidth / contentWidth;
  }
  return 1
}


export const useCalculatedScale = (fn: () => number) =>
  useEventMemo("resize", () => calcScale(fn()))

export const useCalculatedOffset = () =>
  useEventMemo("resize", () => Math.max(
    (document.documentElement.clientWidth - DESKTOP_BREAKPOINT_WIDTH) / 2,
    horizontalPadding()
  ))

