import { CSSProperties, useMemo } from "react";
import styles from "./advantageCard.module.scss";
import { GradientContainer } from "../gradientContainer/gradientContainer";
import { Button } from "../button/button";
import { LazyImage } from "../slider/LazyImage";

export const AdvantageCard = ({
  title,
  backgroundimage,
  description,
  titleTextStyle,
  descriptionTextStyle,
  icon,
  className,
}: {
  title: string;
  backgroundimage: any[];
  description: string;
  titleTextStyle?: string;
  descriptionTextStyle?: string;
  icon?: any[];
  className?: string;
}) => {
  return (
    <div
      className={[styles.container, styles.container__blur, className].join(
        " "
      )}
    >
      <LazyImage className={styles.backgroundImage} image={backgroundimage} />
      <div className={styles.gradient} />
      <div className={styles.upperContainer}>
        <div>{icon && <LazyImage image={icon} />}</div>
        <p className={[styles.titleText, titleTextStyle].join(" ")}>{title}</p>
      </div>
      <div className={[styles.lowerContainer].join(" ")}>
        <p className={[styles.descriptionText, descriptionTextStyle].join(" ")}>
          {description}
        </p>
      </div>
    </div>
  );
};
