import styles from "./modalDetailsMobile.module.scss";
import { ModalContentContainer } from "../modalComponent/modalComponent";
import { CopyButton } from "../copyButton/copyButton";
import { ModalComponentMobile } from "../modalComponentMobile/modalComponentMobile";
import { useRef, useState } from "react";
import up from "../../assets/icons/chevron-up.svg";
import down from "../../assets/icons/chevron-down.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { replaceLinks } from "../../utils/replaceLinks";

export const ModalDetailsMobile = ({
  className,
  onButtonClick,
  onClose,
  content,
}: {
  className?: string;
  onButtonClick?: () => void;
  onClose?: () => void;
  content: any;
}) => {
  const [details, setDetails] = useState<"main" | "bank">("main");

  const bankButtonRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <ModalComponentMobile
      buttonTitle={content.title}
      className={[styles.container, className].join("")}
      onButtonClick={onButtonClick}
      onClose={onClose}
    >
      <div
        ref={scrollRef}
        onScroll={(e) => {
          setDetails(
            e.currentTarget.scrollTop >
              Math.max((bankButtonRef.current?.offsetTop || 0) - 100, 0)
              ? "bank"
              : "main"
          );
        }}
        className={styles.scrollItemContainer}
      >
        <div className={styles.downloadButtonContainer}>
          <CopyButton
            title={content["main"].title}
            copyData={content["main"].items}
          />
          <a
            href={content.downloadurl[0].url}
            download
            target="_blank"
            className={styles.downloadButton}
          >
            <DownloadIcon />
          </a>
        </div>
        {content["main"].items.map((it: any, index: number) => (
          <Item
            {...it}
            className={index === 0 ? styles.itemContainer__first : ""}
          />
        ))}

        <div ref={bankButtonRef}>
          <CopyButton
            title={content["bank"].title}
            copyData={content["bank"].items}
          />
        </div>
        {content["bank"].items.map((it: any, index: number) => (
          <Item
            {...it}
            className={index === 0 ? styles.itemContainer__first : ""}
          />
        ))}
      </div>

      <div className={styles.blur}>
        <BankButton
          title={
            details === "bank" ? "Основные реквизиты" : "Банковские реквизиты"
          }
          onClick={() => {
            if (details === "main") {
              scrollRef.current?.scrollTo({
                top:
                  Math.max((bankButtonRef.current?.offsetTop || 0) - 100, 0) +
                  1,
              });
            } else {
              scrollRef.current?.scrollTo({ top: 0 });
            }
          }}
          icon={details === "bank" ? up : down}
        />
      </div>
    </ModalComponentMobile>
  );
};

const Item = ({
  title,
  info,
  className,
}: {
  title: string;
  info?: string;
  className?: string;
}) => {
  const content = replaceLinks(info || "");
  return (
    <ModalContentContainer
      className={[styles.itemContainer, className].join(" ")}
    >
      <p className={styles.itemTitle}>{title}</p>
      <p
        className={styles.itemInfo}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </ModalContentContainer>
  );
};

const BankButton = ({
  title,
  className,
  onClick,
  icon,
}: {
  title?: string;
  className?: string;
  onClick?: () => void;
  icon?: string;
}) => {
  return (
    <div
      onClick={onClick}
      className={[styles.button, className || ""].join(" ")}
    >
      <img className={styles.buttonIcon} src={icon} />
      <p className={styles.buttonTitle}>{title}</p>
    </div>
  );
};
