import styles from "./animatedScrollArrow.module.scss";

import { ReactComponent as RectangleIcon } from "../../assets/icons/animated_scroll_arrow_part.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/animates_scroll_arrow.svg";

export const AnimatedScrollArrow = () => {
  return (
    <div className={styles.container}>
      <RectangleIcon className={styles.rectangle} />
      <ArrowIcon className={styles.firstArrow} />
      <ArrowIcon className={styles.secondArrow} />
      <ArrowIcon className={styles.thirdArrow} />
    </div>
  );
};
