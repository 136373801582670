import React, { useRef } from "react";

import styles from "./progressBar.module.scss";
import { getScrollProgress, useCurrentPageIdx, } from "../../utils/useCurrentPageIdx";
import { useEventListener } from "usehooks-ts";
import { scrollToBackElement, scrollToNextElement } from "../../utils/utils";

export const ProgressBar = (
  {
    className,
    blackCountScreens,
    pageCount,
  }: {
    className?: string;
    blackCountScreens?: number[];
    pageCount: number;
  } ) => {
  const currentPageIdx = useCurrentPageIdx();
  const barRef = useRef<HTMLDivElement | null>( null );
  useEventListener(
    "scroll",
    () => {
      if ( !barRef.current ) return;
      const progressScrolled = ( ( getScrollProgress() + 1 ) * 100 ) / pageCount;
      barRef.current.style.height = progressScrolled + "%";
    },
    undefined,
    { passive: true }
  );
  const blackMode = blackCountScreens?.includes( currentPageIdx );

  const handleBlockScroll = ( toNext?: boolean ) => {
    if ( toNext ) {
      scrollToNextElement();
    } else {
      scrollToBackElement();
    }
    return;

    const top = Math.floor( currentPageIdx + ( toNext ? 1 : -1 ) ) *
      document.documentElement.clientHeight;
    document.documentElement.scrollTo( {
      behavior: "smooth",
      top: top,
    } );
  };

  return (
    <div className={ [ className, styles.wrapper ].join( " " ) }>
      <div className={ styles.container }>
        <span
          onClick={ () => handleBlockScroll( false ) }
          className={ [
            styles.number,
            styles.number_top,
            blackMode ? styles.number__black : "",
          ].join( " " ) }
        >
          { ( currentPageIdx + 1 ).toString().padStart( 2, "0" ) }
        </span>
        <div
          className={ [
            styles.bar_container,
            blackMode ? styles.bar_container__black : "",
          ].join( " " ) }
        >
          <div
            ref={ barRef }
            className={ [ styles.bar, blackMode ? styles.bar__black : "" ].join(
              " "
            ) }
          />
        </div>
        <span
          onClick={ () => handleBlockScroll( true ) }
          className={ [
            styles.number,
            styles.number_bottom,
            blackMode ? styles.number__black : "",
          ].join( " " ) }
        >
          { pageCount.toString().padStart( 2, "0" ) }
        </span>
      </div>
    </div>
  );
};
