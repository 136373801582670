import React, { ReactNode, useCallback, useEffect, useState } from "react";
import styles from "./AnimatedBlock.module.scss";
import { useEventListener } from "usehooks-ts";
import { getScrollProgress } from "../../utils/useCurrentPageIdx";

const testIsShown = ( isShown?: boolean | number ) => isShown === true || typeof isShown === 'number' && isShown < getScrollProgress()

export const AnimatedBlock = (
  {
    className,
    children,
    isShown,
    latency = 0,
    reversable,
    type = 'div'
  }: {
    className?: string;
    children?: ReactNode;
    isShown?: boolean | number;
    latency?: number;
    reversable?: boolean;
    type?: string
  } ) => {
  const [ blockFirstShown, setBlockFirstShown ] = useState( testIsShown( isShown ) );
  const scrollFn = useCallback( () => {
    if ( reversable && !testIsShown( isShown ) ) {
      setBlockFirstShown( false )
    }
    if ( blockFirstShown ) return
    if ( testIsShown( isShown ) ) {
      setTimeout( () => setBlockFirstShown( true ), latency );
    }
  }, [ isShown, latency, blockFirstShown, reversable ] )
  useEffect( scrollFn, [ isShown ] );
  useEventListener( 'scroll', scrollFn, undefined, { passive: true } )

  const Element = `${ type }`;

  return (
    //@ts-ignore
    <Element
      className={ [
        className,
        styles.container,
        blockFirstShown ? styles.container__shown : "",
      ].join( " " ) }
    >
      { children }
    </Element>
  );
};
