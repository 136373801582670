import { CSSProperties } from "react";
import styles from "./document.module.scss";
import documentPdfIcon from "../../assets/icons/document_pdf.svg";
import documentDocIcon from "../../assets/icons/document_doc.svg";
import { Button } from "../button/button";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";

export interface ClientsDocumentType {
  title: string;
  url: any[];
  type?: string;
}
export const Document = ({
  title,
  titleTextStyle,
  className,
  url,
  type,
}: ClientsDocumentType & {
  titleTextStyle?: CSSProperties;
  className?: string;
}) => {
  return (
    <div className={[styles.container, className || ""].join(" ")}>
      <img
        className={styles.icon}
        src={(type === "pdf" && documentPdfIcon) || documentDocIcon}
      />
      <a
        className={[
          styles.titleText,
          (type === "pdf" && styles.titleText__pdf) || "",
        ].join(" ")}
        style={titleTextStyle}
        target={(type === "pdf" && "_blank") || ""}
        href={(type === "pdf" && url[0].url) || undefined}
      >
        {title}
      </a>
      <a
        href={url[0].url}
        download
        target="_blank"
        className={styles.smallButton}
      >
        <DownloadIcon className={styles.headerButton__icon} />
      </a>

      <div className={styles.headerButtonContainer}>
        <Button
          // onClick={onButtonClick}
          color="light"
          title="Скачать"
          size="small"
          url={url[0].url}
          className={styles.headerButton}
          RightIcon={<DownloadIcon className={styles.headerButton__icon} />}
        />
      </div>
    </div>
  );
};
