import styles from "./gradientContainer.module.scss";

export const GradientContainer = ({
  className,
  children,
}: {
  className?: string;
  children?: any;
}) => {
  return (
    <div
      className={[styles.container, className].join(" ")}
      children={children}
    ></div>
  );
};
