import React, { HTMLProps, forwardRef, useEffect, useState } from "react";
import { KirbyImage } from "./slider";

type ImageType = KirbyImage[] | string | undefined;

export const LazyImage = forwardRef<
  HTMLImageElement,
  { image: ImageType } & HTMLProps<HTMLImageElement>
>(({ image, ...props }, ref) => {
  const [imgSrc, setImgSrc] = useState(
    typeof image === "string" || typeof image === "undefined"
      ? image
      : image[0]?.image.src
  );

  useEffect(() => {
    if (typeof image === "string" || typeof image === "undefined") return;
    const img = new Image();
    img.src = image[0]?.image.url;
    img.onload = () => {
      setImgSrc(image[0]?.image.url);
    };
  }, [image]);

  return <img {...props} src={imgSrc} ref={ref} />;
});

export const LazyImageSwitcher = forwardRef<
  HTMLDivElement,
  {
    images: ImageType[];
    register: (fn: (slide: number) => void) => void;
    unregister: (fn: (slide: number) => void) => void;
    showClassname: string;
    imageClassname: string;
    addContainer: boolean;
    containerClassname?: string;
  } & HTMLProps<HTMLDivElement>
>(
  (
    {
      images,
      register,
      unregister,
      imageClassname,
      showClassname,
      containerClassname,
      addContainer,
      ...props
    },
    ref
  ) => {
    const [selectedMainSlideIdx, setSelectedMainSlideIdx] = useState(0);
    useEffect(() => {
      register(setSelectedMainSlideIdx);
      return () => {
        unregister(setSelectedMainSlideIdx);
      };
    }, [setSelectedMainSlideIdx]);

    if (!addContainer) {
      return (
        <>
          {images.map((image, idx) => (
            <LazyImage
              className={[
                imageClassname,
                idx === selectedMainSlideIdx ? showClassname : "",
              ].join(" ")}
              image={image}
            />
          ))}
        </>
      );
    }

    return (
      <div ref={ref} className={containerClassname}>
        {images.map((image, idx) => (
          <LazyImage
            className={[
              imageClassname,
              idx === selectedMainSlideIdx ? showClassname : "",
            ].join(" ")}
            image={image}
          />
        ))}
      </div>
    );
  }
);
