import styles from "./servicesContainer.module.scss";
import { ServiceCard } from "../serviceCard/serviceCard";
import { forwardRef, useEffect, useRef } from "react";
import { useCalculatedScale } from "../../utils/calcScale";

export const ServicesContainer = forwardRef<
  HTMLDivElement,
  {
    services: Array<any>;
  }
>( ( { services }, ref ) => {
  const scale = useCalculatedScale( () => {
    const cardWidth = 251;
    // TODO: Add proper support for services.length
    return cardWidth * 4 + 24 * 3;
  } );

  const contRef = useRef<HTMLDivElement>( null )

  useEffect( () => {
    if ( contRef.current ) {
      const h = contRef.current?.offsetHeight;
      contRef.current.style.marginBottom = ( h * Math.max( services.length > 4 ? 1 : scale, 0.8 ) - h ) + 'px';
    }
  }, [ scale ] )

  return (
    <div
      className={ [
        styles.mainContainer,
        services.length > 4 ? styles.mainContainer__scroll : "",
      ].join( " " ) }
      ref={ contRef }
    >
      <div
        ref={ ref }
        className={ styles.cardContainer }
        style={ {
          transform: `scale(${ Math.max( services.length > 4 ? 1 : scale, 0.8 ) })`,
        } }
      >
        { services.map( ( it ) => (
          <ServiceCard { ...it } />
        ) ) }
      </div>
    </div>
  );
} );
