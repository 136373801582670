import React from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { LazyImage } from "../../../../components/slider/LazyImage";

interface ItemType {
  icon: string;
  title: string;
  text: string;
}

interface ClientsEsignPageBenefitsBlockType {
  title: string;
  subtitle: string;
  items: ItemType[];
}

export const ClientsEsignPageBenefitsBlock = ( {
                                                 titleShown,
                                                 blocksShown,
                                                 mobile = false,
                                                 content,
                                               }: DesktopBlockPropsType & {
  content: ClientsEsignPageBenefitsBlockType
} ) => {
  return (
    <CommonBlock>
      <div className={ styles.container }>
        <AnimatedBlock isShown={ mobile || titleShown }>
          <>
            <h2 className={ styles.title }>{ content.title }</h2>
            <div className={ styles.subtitle }>{ content.subtitle }</div>
          </>
        </AnimatedBlock>

        <div className={ styles.itemContainer }>
          { content.items.map( ( _item, index ) => (
            <>
              { index !== 0 && (
                <AnimatedBlock
                  className={ styles.animatedItem + " " + styles.item__delimiter }
                  isShown={ mobile || blocksShown }
                  latency={ index * 300 }
                />
              ) }

              <AnimatedBlock
                className={ [
                  styles.animatedItem,
                  styles.item,
                  styles[ "item_" + ( index + 1 ) ],
                ].join( " " ) }
                isShown={ mobile || blocksShown }
                latency={ index * 300 }
              >
                <LazyImage image={ _item.icon } className={ styles.item__icon }/>
                <div className={ styles.item__title }>{ _item.title }</div>
                <div className={ styles.item__text }>{ _item.text }</div>
              </AnimatedBlock>
            </>
          ) ) }
        </div>
      </div>
    </CommonBlock>
  );
};
