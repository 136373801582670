import React from "react";
import styles from "./cardWithImage.module.scss";
import { Button } from "../button/button";
import { useNavigate } from "react-router-dom";
import { programNames } from "../../pages/program/types/programNames";
import { LazyImage } from "../slider/LazyImage";
import { useIsMobile } from "../../utils/common";
import dotGray from "../../assets/icons/rectangle_gray.svg";
import dotGreen from "../../assets/icons/rectangle_green.svg";
import { ObjectString } from "../../pages/program/types/blockTypes";

export interface CardWithImageContent {
  title: string;
  buttonlabel: string;
  descriptionlines: ObjectString[];
}

export const CardWithImage = (
  {
    containerClassName,
    content,
    backgroundImage,
    short = false,
    active = false,
    type,
  }: {
    containerClassName?: string;
    content: CardWithImageContent;
    backgroundImage?: any;
    type: programNames;
    active?: boolean;
    short?: boolean;
  } ) => {
  const navigate = useNavigate();
  const goToProgram = () => navigate( "/program/" + type );

  const isMobile = useIsMobile();

  return (
    <div
      className={ [
        styles.container,
        containerClassName || "",
        active ? styles.container_active : "",
        short ? styles.container_short : "",
      ].join( " " ) }
      onClick={ goToProgram }
    >
      <div
        className={ [
          styles.leftColumn,
          short ? styles.leftColumn_short : "",
        ].join( " " ) }
      >
        <div className={ styles.leftColumn__top }>
          <div className={ styles.title }>{ content.title }</div>
          <Button
            className={ [ styles.button, active ? styles.button_active : "" ].join(
              " "
            ) }
            title={ content.buttonlabel }
          />
        </div>
        <div
          className={ [
            styles.description,
            short ? styles.description_short : "",
          ].join( " " ) }
        >
          { content.descriptionlines.map( ( _line ) => (
            <div
              className={ [
                styles.descriptionItem,
                short ? styles.descriptionItem_short : "",
              ].join( " " ) }
            >
              <img
                className={ styles.dot }
                src={ short ? dotGreen : isMobile ? dotGreen : dotGray }
              />
              <div className={ styles.descriptionLineText }>{ _line.value }</div>
            </div>
          ) ) }
        </div>
      </div>
      { !short && (
        <div className={ styles.rightColumn }>
          <Button
            // onClick={goToProgram}
            className={ [
              styles.activeButton,
              active ? styles.activeButton_active : "",
            ].join( " " ) }
            title={ "Подробнее" }
          />
        </div>
      ) }

      { !short && backgroundImage && (
        <LazyImage className={ styles.backgroundImage } image={ backgroundImage }/>
      ) }
    </div>
  );
};
