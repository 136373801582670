import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { CustomCollapsible } from "../../../../components/collapsible/collapsible";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { PageMarker } from "../../../../components/pageMarker/pageMarker";
import _ from "lodash";
import { CardContext } from "../../../../components/cardTransitionWrapper/cardTransitionWrapper";

interface ProgramPageQuestionsBlockContentType {
  questionlist: { item: string; value: string }[];
  pageindicatoroverlaydesktop?: boolean;
  pageindicatoroverlaymobile?: boolean;
}

const temp = {
  h: 0
}

export const ProgramPageQuestionsBlock = (
  {
    blocksShown,
    onQuestionChange,
    content,
  }: DesktopBlockPropsType & {
    onQuestionChange?: () => void;
    content: ProgramPageQuestionsBlockContentType;
  } ) => {
  const questions = _.chunk( content.questionlist, 4 );

  const cardRef = useContext( CardContext );

  const [ currentSlideIdx, setCurrentSlideIdx ] = useState( 0 );

  const containerRef = useRef<HTMLDivElement>( null );

  const markerRef = useRef<HTMLDivElement>( null );

  const updateMarkerPosition = ( height?: number ) => {
    if ( containerRef.current && markerRef.current ) {
      //@ts-ignore
      let lastChild = containerRef.current.children[ currentSlideIdx ].lastElementChild.lastElementChild.lastElementChild as HTMLDivElement;

      if ( height !== undefined ) {
        markerRef.current.style.top = ( Number( markerRef.current.style.top.replace( /[^0-9]/g, '' ) ) + ( height === 0 ? -temp.h : height ) ) + 'px';
        temp.h = height;
        setTimeout( updateMarkerPosition, 350 );
      } else {
        markerRef.current.style.top = ( lastChild?.offsetTop + lastChild?.clientHeight + 30 ) + 'px';
        //@ts-ignore
        cardRef.ref?.current?.updateHeightContainer();
      }
    }
  }

  useEffect( () => {
    updateMarkerPosition();
    const scrollToSelected = () => {
      if ( !containerRef.current ) return;

      const { x } =
        containerRef.current.children[ currentSlideIdx ].getBoundingClientRect();
      containerRef.current.scrollBy( {
        left: x,
        behavior: "smooth",
      } );
    };

    scrollToSelected();

    window.addEventListener( "resize", scrollToSelected );
    return () => {
      window.removeEventListener( "resize", scrollToSelected );
    };
  }, [ currentSlideIdx ] );

  return (
    <CommonBlock fullWidth>
      <div className={ styles.mobileContainer }>
        { questions.flat().map( ( it ) => (
          <ListItem
            onQuestionChange={ onQuestionChange }
            item={ { text: it.value, title: it.item } }
          />
        ) ) }
      </div>

      <div ref={ containerRef } className={ styles.container }>
        { questions.map( ( _slide ) => (
          <AnimatedBlock isShown={ blocksShown } className={ styles.wrapper }>
            <div className={ styles.slide }>
              <div className={ styles.itemContainer }>
                { _slide.map( ( it ) => (
                  <ListItem item={ { text: it.value, title: it.item } }
                            onChange={ updateMarkerPosition }/>
                ) ) }
              </div>
            </div>
          </AnimatedBlock>
        ) ) }
      </div>

      <div ref={ markerRef } className={ styles.pageMarkerWrapper }>
        <div className={ styles.pageMarkerContainer }>
          <PageMarker
            overlay={ content.pageindicatoroverlaydesktop }
            currentPage={ currentSlideIdx }
            pages={ questions.length }
            onPageSelect={ setCurrentSlideIdx }
          />
        </div>
      </div>
    </CommonBlock>
  );
};

const ListItem = (
  {
    item,
    onQuestionChange,
    onChange
  }: {
    item: { title: string; text: string };
    onQuestionChange?: () => void;
    onChange?: ( height?: number ) => void;
  } ) => {
  return (
    <CustomCollapsible onQuestionChange={ onQuestionChange } title={ item.title } onChange={ onChange }>
      <p>{ item.text }</p>
    </CustomCollapsible>
  );
};
