import React, { ReactNode, useEffect, useState } from "react";
import styles from "./commonModalWrapper.module.scss";

export const CommonModalWrapper = ({
  children,
  isOpen,
  onClose,
}: {
  children?: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const [isActualOpen, setIsActualOpen] = useState(isOpen);

  const animationDelayMs = 300;

  useEffect(() => {
    setTimeout(() => setIsActualOpen(isOpen), animationDelayMs);
  }, [isOpen]);

  const [animationStart, setAnimationStart] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimationStart(isOpen), 10);
  }, [isOpen]);

  return (
    <>
      {(isOpen || isActualOpen) && (
        <div className={[styles.container].join(" ")}>
          <div
            className={[
              styles.wrapper,
              isOpen && animationStart ? styles.wrapper_open : "",
            ].join(" ")}
          >
            {children}
          </div>
          <div
            onClick={onClose}
            className={[
              styles.overlay,
              isOpen && animationStart ? styles.overlay_open : "",
            ].join(" ")}
          />
        </div>
      )}
    </>
  );
};
