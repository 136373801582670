import { CSSProperties, ReactNode, useEffect, useRef } from "react";
import styles from "./tabs.module.scss";
import { isWithoutParent } from "../../utils/utils";

export const Tab = (
  {
    title,
    style,
    onClick,
    selected,
    className,
    LeftIcon,
    dark = false,
  }: {
    style?: CSSProperties;
    title: string;
    selected?: boolean;
    onClick: () => void;
    className?: string;
    LeftIcon?: ReactNode;
    dark?: boolean;
  } ) => {
  const ref = useRef<HTMLDivElement>( null );

  const click = () => {
    onClick();
    scrollToTab();
  }

  const scrollToTab = () => {
    if ( ref.current && ref.current.parentElement ) {
      if ( isWithoutParent( ref.current ) ) {
        if ( ref.current.offsetLeft > ref.current.parentElement.clientWidth - ref.current.offsetWidth ) {
          ref.current.parentElement.scrollTo( {
            behavior: 'smooth',
            left: ref.current.parentElement.scrollLeft + ref.current.offsetWidth
          } )
        } else {
          ref.current.parentElement.scrollTo( {
            behavior: 'smooth',
            left: ref.current.parentElement.scrollLeft - ref.current.offsetWidth
          } )
        }
      }
    }
  }

  useEffect( () => {
    if ( selected ) scrollToTab();
  }, [ selected ] )

  return (
    <div
      ref={ ref }
      style={ style }
      onClick={ click }
      className={ [
        styles.button,
        dark ? styles.button__dark : "",
        selected
          ? dark
            ? styles.button__dark__selected
            : styles.button__green
          : "",
        className || "",
      ].join( " " ) }
    >
      { LeftIcon }
      { title }
    </div>
  );
};

export const Tabs = (
  {
    tabs,
    selectedIdx,
    onSelectTab,
    style,
    dark = false,
    containerClassName,
    backbuttonComponent,
    menulinks,
  }: {
    tabs?: Array<string> | null;
    selectedIdx: number;
    onSelectTab: ( idx: number ) => any;
    style?: CSSProperties;
    dark?: boolean;
    containerClassName?: string;
    backbuttonComponent?: ReactNode;
    menulinks?: ReactNode;
  } ) => {
  return (
    <div
      style={ style }
      className={ [ styles.container, containerClassName || " " ].join( " " ) }
    >
      { backbuttonComponent }
      { menulinks }
      { tabs &&
        tabs.map( ( it, index ) => (
          <Tab
            title={ it }
            onClick={ () => onSelectTab( index ) }
            selected={ index === selectedIdx }
            dark={ dark }
          />
        ) ) }
      <div className={ styles.specialPlaceholder }>
        <div className={ styles.specialPlaceholder }/>
      </div>
    </div>
  );
};
