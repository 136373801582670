import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./collapsible.module.scss";
import close from "../../assets/icons/x.svg";

export const CustomCollapsible = (
  {
    children,
    onQuestionChange,
    onChange,
    title,
  }: {
    children?: ReactNode;
    onQuestionChange?: () => void;
    onChange?: ( height?: number ) => void;
    title?: string;
  } ) => {
  const [ isOpen, setIsOpen ] = useState( false );

  const contentRef = useRef<HTMLDivElement>( null );

  useEffect( () => {
    onQuestionChange && setTimeout( onQuestionChange, 300 );
    //@ts-ignore
    if ( onChange ) onChange( Number( contentRef.current.style.height.replace( /[^0-9]/g, '' ) || 0 ) );
  }, [ isOpen ] );

  return (
    <div
      className={ styles.collapsible }
      onClick={ () => {
        setIsOpen( ( prevState ) => !prevState );
        // if ( onChange ) onChange();
      } }
    >
      <div className={ styles.title }>{ title }</div>
      <div
        className={ [
          styles.closeButton,
          isOpen ? styles.closeButton_open : "",
        ].join( " " ) }
      >
        <img src={ close }/>
      </div>
      <div
        ref={ contentRef }
        className={ styles.content }
        style={ {
          height: isOpen ? contentRef.current?.scrollHeight || 0 : 0,
        } }
      >
        <div className={ styles.content__text }>{ children }</div>
      </div>
    </div>
  );
};
