import styles from "./searchButton.module.scss";
import searchLight from "../../assets/icons/search_light.svg";
import searchDark from "../../assets/icons/search_dark.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { SearchType } from "../../pages/program/types/blockTypes";

const pageTitles = {
  "/": "Главная",
  "/about-us": "О компании",
  "/services": "Услуги",
  "/clients": "Клиентам",
  "/clients/esign": "Клиентам",
  "/options": "Карта помощи",
  "/program/standard": "Стандарт",
  "/program/express": "Экспресс",
  "/program/facilities": "Лизинг оборудования",
  "/program/preferential": "Льготный лизинг",
};

const searchDefinitions = [
  {
    location: "/#calculator",
    terms: [ "калькулятор" ],
  },
  {
    location: "/#comfort",
    terms: [ "преимущества компании" ],
  },
  {
    location: "/#feedback",
    terms: [ "оставить заявку", "заявка" ],
  },
  {
    location: "/about-us#management",
    terms: [ "руководство" ],
  },
  {
    location: "/about-us#contacts",
    terms: [
      "контакты",
      "адрес",
      "почта",
      "емейл",
      "e-mail",
      "телефон",
      "стать партнером",
      "партнерам",
      "партнеры",
    ],
  },
  {
    location: "/about-us#legal-details",
    terms: [ "реквизиты компании" ],
  },
  {
    location: "/services#programs",
    terms: [ "список программ", "программы лизинга" ],
  },
  {
    location: "/services#options",
    terms: [ "опции", "список опций" ],
  },
  // {
  //   location: "/services#promotion",
  //   terms: [ "акции", "список акций", "скидки", "скидка" ],
  // },
  // {
  //   location: "/clients#loyalty",
  //   terms: [ "программы лояльности" ],
  // },
  {
    location: "/clients/esign#main",
    terms: [ "ЭДО", "электронный документооборот" ],
  },
  {
    location: "/clients#documents",
    terms: [
      "документы",
      "правила лизинга",
      "шаблоны",
      "заявка",
      "шаблоны на заявку",
    ],
  },
  {
    location: "/program/standard#main",
    terms: [ "Программа Стандарт", "Стандарт" ],
  },
  {
    location: "/program/express#main",
    terms: [ "Программа Экспресс", "Экспресс" ],
  },
  {
    location: "/program/facilities#main",
    terms: [ "Программа Лизинг оборудования", "Лизинг оборудования" ],
  },
  {
    location: "/program/preferential#main",
    terms: [ "Программа Льготный лизинг", "Льготный лизинг" ],
  },
  {
    location: "/options#main",
    terms: [ "Карта помощи", "Опция Карта помощи" ],
  },
  {
    location: "/services#cash_back",
    terms: [ "Опция Кэшбэк", "Кэшбэк" ],
  },
  {
    location: "/services#light_start",
    terms: [ "Опция Лёгкий старт", "Лёгкий старт" ],
  },
  {
    location: "/services#hard_start",
    terms: [ "Опция Мощный старт", "Мощный старт" ],
  },
];

type SearchResult = {
  title: string;
  category: string;
  location: string;
  key: string;
};

export const SearchButton = (
  {
    mobile = false,
    searchData,
  }: {
    mobile?: boolean;
    searchData: SearchType;
  } ) => {
  const [ isBig, setIsBig ] = useState( false );
  const [ value, setValue ] = useState( "" );
  const inputRef = useRef<HTMLInputElement>( null );

  const searchIndex: SearchResult[] = [];

  for ( const el of searchData?.searchdefinitions ) {
    const pureLocation = el.location.split( "#" )[ 0 ];
    // @ts-ignore
    const category = searchData?.pagetitles[ pureLocation ];

    for ( const term of el.terms ) {
      searchIndex.push( {
        title: term.value,
        location: el.location,
        category,
        key: el.terms[ 0 ].value,
      } );
    }
  }

  const searchResults = useMemo( () => {
    const terms = value.toLowerCase().replaceAll( "ё", "е" ).trim().split( " " );
    const foundKeys = new Set();
    return searchIndex.filter( ( it ) => {
      if ( foundKeys.has( it.key ) ) return false;
      const title = it.title.toLowerCase().replaceAll( "ё", "е" );
      const found = terms.reduce(
        ( acc, val ) => acc && title.includes( val ),
        true
      );
      if ( found ) foundKeys.add( it.key );
      return found;
    } );
  }, [ value ] );

  useEffect( () => {
    isBig && inputRef?.current?.focus();
    console.log( inputRef.current );
  }, [ inputRef, isBig ] );

  const reset = () => {
    setTimeout( () => {
      setIsBig( false );
      setValue( "" );
    }, 200 );
  };

  const isInputting = isBig && value;

  return (
    <div className={ styles.outterContainer }>
      <div
        onClick={ () => setIsBig( true ) }
        className={ [
          styles.container,
          ( isBig && styles.container__large ) || "",
          isInputting && styles.container__focused,
        ].join( " " ) }
      >
        { isBig && (
          <input
            type={ "text" }
            className={ styles.input }
            onChange={ ( text ) => setValue( text.target.value ) }
            value={ value }
            ref={ inputRef }
            onBlur={ () => setTimeout( () => setIsBig( false ), 200 ) }
          />
        ) }
        { isBig && !value && <p className={ styles.title }>Поиск</p> }

        <img src={ mobile ? searchDark : searchLight }/>
      </div>
      { isInputting && (
        <DropdownList results={ searchResults } onSelected={ reset }/>
      ) }
    </div>
  );
};

const DropdownList = (
  {
    results,
    onSelected,
  }: {
    results?: SearchResult[];
    onSelected: () => void;
  } ) => {
  return (
    <div
      className={ [
        styles.dropdownContainer,
        ( !results && styles.dropdownContainer__small ) || "",
      ].join( " " ) }
    >
      { results && results.length > 0 ? (
        results.map( ( it ) => <Item { ...it } onClick={ onSelected }/> )
      ) : (
        <div className={ styles.listItem }>
          <p className={ styles.itemTitle }>Ничего не нашлось</p>
          <p className={ styles.itemDescription }>
            Попробуйте изменить поисковой запрос
          </p>
        </div>
      ) }
    </div>
  );
};

const Item = (
  {
    title,
    category,
    location,
    onClick,
  }: SearchResult & { onClick: () => void } ) => {
  let loc = location.split( '#' );
  location = `${ loc[ 0 ] }?title=${ title }#${ loc[ 1 ] || '' }`;

  return (
    <Link className={ styles.listItem } to={ location } onClick={ onClick }>
      <p className={ styles.itemTitle }>{ title }</p>
      <p className={ styles.itemDescription }>{ category }</p>
    </Link>
  );
};
