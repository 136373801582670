import { FormatRegistry, Static, TSchema, Type } from "@sinclair/typebox";
import { useMemo, useState } from "react";
import { Value } from "@sinclair/typebox/value";
import { DefaultErrorFunction, SetErrorFunction } from "@sinclair/typebox/errors";

FormatRegistry.Set( "email", ( value ) =>
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
    value
  )
);

FormatRegistry.Set( "name", ( value ) => /^[а-яА-ЯёЁa-zA-Z-\s]+$/i.test( value ) );

FormatRegistry.Set( "phone", ( value ) =>
  /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/i.test( value )
);

FormatRegistry.Set( "company", ( value ) =>
  /^([0-9]{10}|[0-9]{12}|)$/i.test( value )
);

SetErrorFunction( ( error ) => {
  const err = getError( error.schema.format || error.path )
  if ( err ) return err;
  return DefaultErrorFunction( error );
} )

function getError( format: string ) {
  format = format.replace( /[^a-zA-Z]/g, '' )
  switch ( format ) {
    case "phone":
      return 'Телефон указан неверно. Должно быть 11 цифр, например: +7 (901) 123-45-67';
    case "email":
      return 'Email введен некорректно. Пример: email@example.com';
    case 'company':
      return 'ИНН состоит из 10 или 12 цифр';
    case 'name':
      return 'Имя введено некорректно. Допустимы только буквы русского и английского алфавита';
    case 'topic':
      return 'Пожалуйста выберите тему обращения';
    case 'city':
      return 'Пожалуйста выберите город';
    default:
      return '';
  }
}

export const CITIES = [
  "Москва",
  "Казань",
  "Санкт-Петербург",
  "Краснодар",
  "Новосибирск",
  "Хабаровск",
  "Екатеринбург",
  "Набережные Челны",
].sort( ( a, b ) => a.localeCompare( b ) );

export const BASE_APPLICATION_SCHEMA = Type.Object( {
  name: Type.String( { format: "name", default: "" } ),
  phone: Type.String( { format: "phone", default: "" } ),
  company: Type.String( { format: "company", default: "" } ),
  city: Type.String( { minLength: 1, default: "" } ),
  email: Type.String( { format: "email", default: "" } ),
} );

const CALCULATION_SCHEMA = Type.Object( {
  total: Type.Number(),
  minmonths: Type.Number(),
  firstPayment: Type.Number(),
  monthlyPayment: Type.Number(),
  sumContract: Type.Number(),
} );

export const PARTNERSHIP_APPLICATION_SCHEMA = Type.Object( {
  name: Type.String( { default: "", format: "name" } ),
  phone: Type.String( { default: "", format: "phone" } ),
  company: Type.String( { format: "company", default: "" } ),
  activity: Type.String(),
  store: Type.String(),
  email: Type.String( { format: "email", default: "" } ),
  comment: Type.String(),
} );

export const APPEAL_APPLICATION_SCHEMA = Type.Object( {
  topic: Type.String( { minLength: 1, default: "" } ),
  name: Type.String( { format: "name", default: "" } ),
  phone: Type.String( { format: "phone", default: "" } ),
  company: Type.String( { format: "company", default: "" } ),
  media: Type.String(),
  appeal: Type.String(),
  comment: Type.String(),
} );

export const APPEAL_APPLICATION_SCHEMA_TOPIC = Type.Object( {
  topic: Type.String( { minLength: 1, default: "" } ),
  name: Type.String( { format: "name", default: "" } ),
  phone: Type.String( { format: "phone", default: "" } ),
  company: Type.String( { format: "company", default: "" } ),
  media: Type.String(),
  appeal: Type.String(),
  comment: Type.String(),
} );

// TODO: Lift code from react-hook-form resolvers?
export const useForm = <T extends TSchema>( SCHEMA: T ) => {
  const [ data, setData ] = useState<Static<T>>( Value.Create( SCHEMA ) );
  const [ errors, setErrors ] = useState<any>( {} );
  const setters = useMemo<any>( () => {
    const it = {};
    // @ts-ignore
    Object.keys( SCHEMA.properties ).forEach(
      ( a ) =>
        // @ts-ignore
        ( it[ a ] = ( val ) => {
          setData( ( prev ) => ( {
            // @ts-ignore
            ...prev,
            [ a ]: val,
          } ) );
          setErrors( ( prev: any ) => {
            const newErrors = {
              ...prev,
            };
            // @ts-ignore
            delete newErrors[ a ];
            return newErrors;
          } );
        } )
    );
    return it;
  }, [ SCHEMA ] );

  const isValidationPassed = () => {
    const newErrors = {}; // @ts-ignore
    for ( const error of [ ...Value.Errors( SCHEMA, data ) ] ) {
      // @ts-ignore
      newErrors[ error.path.slice( 1 ) ] = error.message;
    }

    if ( Object.keys( newErrors ).length === 0 ) {
      return true;
    } else {
      setErrors( newErrors );
      return false;
    }
  };

  return { data, errors, setters, isValidationPassed };
};
