import React, { useState } from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { AnimatedBlock } from "../../../../components/animatedBlock/AnimatedBlock";
import { Button } from "../../../../components/button/button";
import { useCalculatedScale } from "../../../../utils/calcScale";
import { LazyImage } from "../../../../components/slider/LazyImage";
import { modaldata } from "../../../program/types/blockTypes";

export type CardProps = {
  image: string;
  title: string;
  shorttext?: string;
  fulltext?: string;
  to?: string;
  totitle?: string;
  onClick?: () => void;
  modaldata?: modaldata;
  type?: string;
};

export const CardItem = (props: CardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={styles.item__preview}>
        <LazyImage image={props.image} className={styles.item__image} />
        <div className={styles.item__title}>{props.title}</div>
        {props.shorttext && (
          <div className={styles.item__text}>{props.shorttext}</div>
        )}
      </div>
      <div className={styles.underline}>
        <Button
          to={props.to}
          //onClick={() => setIsOpen(!isOpen)}
          onClick={props.onClick}
          title={props.totitle || "Подробнее"}
          className={styles.button}
        />
      </div>

      <div className={[styles.overlay, isOpen ? styles.active : ""].join(" ")}>
        <div className={styles.overlay__title}>{props.title}</div>
        <div className={styles.overlay__text}>{props.fulltext}</div>
        <div className={styles.underline}>
          <Button
            onClick={() => setIsOpen(!isOpen)}
            title={"Скрыть"}
            className={styles.button}
            color="greenInverted"
          />
        </div>
      </div>
    </>
  );
};

interface ClientsPageLoyaltyBlockType {
  disclaimer: string;
  items: CardProps[];
}

export const ClientsPageLoyaltyBlock = ({
  blocksShown,
  mobile = false,
  content,
  onProgramSelect,
}: DesktopBlockPropsType & {
  content: ClientsPageLoyaltyBlockType;
  onProgramSelect: (programName: string) => void;
}) => {
  const scale = useCalculatedScale(() => {
    const { clientWidth } = document.documentElement;
    if (clientWidth >= 1440) return 1200;
    if (clientWidth >= 1280) return 1300;
    return 1150;
  });

  const handleModals = (type: string) => {
    onProgramSelect(type);
  };
  return (
    <CommonBlock>
      <div
        className={styles.container}
        style={{
          transform: `scale(${scale}) translateX(${(scale - 1) * 40}%)`,
        }}
      >
        <div className={styles.itemContainer}>
          {content.items.map((_item, index) => (
            <AnimatedBlock
              className={[
                styles.animatedItem,
                styles.item,
                styles["item_" + (index + 1)],
              ].join(" ")}
              isShown={mobile || blocksShown}
              latency={index * 300}
            >
              <CardItem
                {..._item}
                onClick={() => handleModals(_item.type || "")}
              />
            </AnimatedBlock>
          ))}
        </div>
        <div className={styles.disclaimer}>{content.disclaimer}</div>
      </div>
    </CommonBlock>
  );
};
