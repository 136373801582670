import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { PageMarker } from "../../../../components/pageMarker/pageMarker";
import { Button } from "../../../../components/button/button";
import { AnimatedScrollArrow } from "../../../../components/animatedScrollArrow/animatedScrollArrow";
import { getScrollProgress } from "../../../../utils/useCurrentPageIdx";
import { useEventMemo, useIsMobile } from "../../../../utils/common";
import { MainBlockSlideType } from "../../../program/types/blockTypes";

interface MainPageMainBlockType {
  pageindicatoroverlaydesktop?: boolean;
  pageindicatoroverlaymobile?: boolean;
  slides: MainBlockSlideType[];
  promotionduration?: string;
  applicationbutton: string;
  phone: {
    hoverlink: string;
    hoverlinkto: string;
  };
}

export const MainPageMainBlock = ({
  onApplicationButtonPress,
  onSlidePress,
  content,
}: DesktopBlockPropsType & {
  onApplicationButtonPress: () => void;
  onSlidePress: (idx: number) => void;
  content: MainPageMainBlockType;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  const onNextSlidePress = () => {
    const newSlideIdx =
      currentSlideIdx + 1 >= content.slides.length ? 0 : currentSlideIdx + 1;
    setCurrentSlideIdx(newSlideIdx);
    onSlidePress(newSlideIdx);
  };

  const isMobile = useIsMobile();

  const selectNextSlide = (index: number) => {
    setCurrentSlideIdx(index);
    onSlidePress(index);
  };

  useEffect(() => {
    const scrollToSelected = () => {
      if (!containerRef.current) return;

      const { x } =
        containerRef.current.children[currentSlideIdx].getBoundingClientRect();
      containerRef.current.scrollBy({
        left: x,
        behavior: "smooth",
      });
    };

    scrollToSelected();

    window.addEventListener("resize", scrollToSelected);
    return () => {
      window.removeEventListener("resize", scrollToSelected);
    };
  }, [currentSlideIdx]);

  const PROMOTION_DURATION_MS = content.promotionduration
    ? Number(content.promotionduration) * 1000
    : 5000;

  const allowSlidesChange = useEventMemo(
    "scroll",
    () => getScrollProgress() < 0.1
  );

  useEffect(() => {
    const interval = setInterval(() => {
      allowSlidesChange && onNextSlidePress();
    }, PROMOTION_DURATION_MS);

    return () => {
      clearInterval(interval);
    };
  }, [allowSlidesChange, currentSlideIdx]);

  return (
    <CommonBlock fullWidth>
      <div ref={containerRef} className={styles.container}>
        {content.slides.map((_promo) => (
          <div className={styles.wrapper}>
            <div className={styles.slide}>
              <div className={styles.title}>{_promo.title}</div>

              <div className={styles.buttonContainer}>
                <Button
                  className={styles.button}
                  onClick={onApplicationButtonPress}
                  size={"average"}
                  title={content?.applicationbutton}
                />
              </div>
              <div className={styles.animatedArrow}>
                <AnimatedScrollArrow />
              </div>
            </div>
          </div>
        ))}
      </div>
      <a className={styles.phone} href={content.phone.hoverlinkto}>
        {content.phone.hoverlink}
      </a>

      <div className={styles.pageMarkerContainer}>
        <PageMarker
          onPageSelect={selectNextSlide}
          currentPage={currentSlideIdx}
          pages={content.slides.length}
          overlay={
            isMobile
              ? content.pageindicatoroverlaymobile
              : content.pageindicatoroverlaydesktop
          }
        />
      </div>
    </CommonBlock>
  );
};
