import { BlockTypes, ModalTypes } from "../program/types/blockTypes";
import { ProgramPageMainBlock } from "../program/blocks/main";
import { ProgramPageAboutBlock } from "../program/blocks/about";
import { MainPageCalculatorBlock } from "../main/blocks/calculator";
import { ServicesPageOptionsBlock } from "../services/blocks/options";
import { ProgramPageDocumentsBlock } from "../program/blocks/documents";
import { ProgramPageConditionsBlock } from "../program/blocks/conditions";
import { ProgramPageQuestionsBlock } from "../program/blocks/questions";
import React from "react";
import { ModalOptionMobile } from "../../components/modals/modalOptionMobile/modalOptionMobile";
import { ModalOption } from "../../components/modals/modalOption/modalOption";
import { ModalApplicationMobile } from "../../components/modals/modalApplicationMobile/modalApplicationMobile";
import { ModalApplicationProgram } from "../../components/modals/modalApplicationProgram/modalApplicationProgram";
import { ServicesPageProgramsBlock } from "../services/blocks/programs";
import { ServicesPagePromotionBlock } from "../services/blocks/promotion";
import { ModalSpecialOrder } from "../../components/modals/modalSpecialOrder/modalSpecialOrder";
import { ModalSpecialOrderMobile } from "../../components/modals/modalSpecialOrderMobile/modalSpecialOrderMobile";
import { OptionsPageBenefitsBlock } from "../options/blocks/benefits";
import { OptionsPageFunctionsBlock } from "../options/blocks/functions";
import { OptionsPageCombinationsBlock } from "../options/blocks/combinations";
import { ClientsPageLoyaltyBlock } from "../clients/blocks/loyalty";
import { ClientsPageServicesBlock } from "../clients/blocks/services";
import { ClientsPageDocumentsBlock } from "../clients/blocks/documents";
import { ClientsEsignPageBenefitsBlock } from "../clients_esign/blocks/benefits";
import { ClientsEsignPageDocumentsBlock } from "../clients_esign/blocks/documents";
import { ClientsEsignPageGuideBlock } from "../clients_esign/blocks/guide";
import { AboutUsPageManagementBlock } from "../aboutUs/blocks/management";
import { AboutUsPageCardsBlock } from "../aboutUs/blocks/cards";
import { AboutUsPageLeasingBlock } from "../aboutUs/blocks/leasing";
import { AboutUsPageSlidesBlock } from "../aboutUs/blocks/slides";
import { AboutUsPageMissionBlock } from "../aboutUs/blocks/mission";
import { AboutUsPageAboutUsBlock } from "../aboutUs/blocks/about-us";
import { MainPageFormBlock } from "../main/blocks/form";
import { ModalContacts, ModalContactsMobile, } from "../../components/modalCotacts/modalContacts";
import { ModalDetailsMobile } from "../../components/modalDetailsMobile/modalDetailsMobile";
import { ModalDetails } from "../../components/modalDetails/modalDetails";
import _ from "lodash";
import { MainPageMainBlock } from "../main/blocks/main";
import { ProgramPageDiscountsBlock } from "../program/blocks/discount";

export const pageNameHandler = ( blockType: BlockTypes ) => {
  switch ( blockType ) {
    case BlockTypes.BENEFITS_TEXT:
      return "Преимущества";
    case BlockTypes.PAGE_GUIDE:
      return "Как получать документы в ЭДО";
    case BlockTypes.ABOUT_US:
      return "О нас";
    case BlockTypes.MISSION:
      return "Наша миссия";
    case BlockTypes.SLIDES:
      return "Наши принципы";
    case BlockTypes.LEASING:
      return "Лизинг под ваши планы";
    case BlockTypes.CARDS:
      return "С комфортом для бизнеса";
    case BlockTypes.MANAGEMENT:
      return "Руководство";
    case BlockTypes.LOYALTY:
      return "Программы лояльности";
    case BlockTypes.SERVICES:
      return "Онлайн-сервисы";
    case BlockTypes.BENEFITS_CARDS:
      return "Преимущества";
    case BlockTypes.FUNCTIONS:
      return "Доступные функции";
    case BlockTypes.COMBINATIONS:
      return "Сочетается с программами";
    case BlockTypes.MAIN:
      return "";
    case BlockTypes.ABOUT:
      return "О программе";
    case BlockTypes.PROGRAMS:
      return "Программы";
    case BlockTypes.OPTIONS:
      return "Опции";
    case BlockTypes.CALCULATOR:
      return "Калькулятор";
    case BlockTypes.DOCUMENTS_FILES:
      return "Документы";
    case BlockTypes.DOCUMENTS_DESCRIPTION:
      return "Документы";
    case BlockTypes.CONDITIONS:
      return "Условия";
    case BlockTypes.PROMOTION:
      return "Акции";
    case BlockTypes.QUESTIONS:
      return "Вопросы";
    case BlockTypes.FORM:
      return "Заявка";
    case BlockTypes.DISCOUNTS:
      return "Размер скидки";
  }
};

// TODO: Consider making it an actual React context
interface BlockContainerContext {
  mobile?: boolean;
  onHeightsChanged?: () => void;
  openModal: ( modalName: string ) => void;
  changeBackground: ( key: string, slide: number ) => void;
  setAddCalculation?: ( a: boolean ) => void;
}

function replaceStringsInObject(
  obj: any,
  replacer: ( str: string ) => string
): any {
  if ( typeof obj === "string" ) {
    return replacer( obj );
  } else if ( Array.isArray( obj ) ) {
    return obj.map( ( item ) => replaceStringsInObject( item, replacer ) );
  } else if ( typeof obj === "object" && obj !== null ) {
    const newObj: { [ key: string ]: any } = {};
    for ( const key in obj ) {
      if ( obj.hasOwnProperty( key ) ) {
        newObj[ key ] = replaceStringsInObject( obj[ key ], replacer );
      }
    }
    return newObj;
  }
  return obj;
}

export const blockHandler = (
  block: { content: any; type: BlockTypes; key: string },
  index: number = -1,
  ctx: BlockContainerContext = {
    openModal: ( name ) => {
      console.log( "openModal", name );
      // @ts-ignore
      window.openModal?.( name );
    },
    changeBackground: ( name, slide ) => {
      console.log( "changeBackground", name );
      // @ts-ignore
      window.changeBackground?.( name );
    },
  }
) => {
  const content = replaceStringsInObject( _.cloneDeep( block.content ), ( str ) =>
    str.replaceAll( "\\n", "\n" ).replaceAll( "\\u00A0", "\u00A0" )
  );

  switch ( block.type ) {
    case BlockTypes.FORM:
      return (
        <MainPageFormBlock
          blocksShown={ index + 0.8 }
          titleShown={ index + 0.6 }
          content={ content }
        />
      );
    case BlockTypes.ABOUT_US:
      return (
        <AboutUsPageAboutUsBlock
          blocksShown={ index + 0.6 }
          titleShown={ index + 0.5 }
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
        />
      );
    case BlockTypes.MISSION:
      return (
        <AboutUsPageMissionBlock
          blocksShown={ index + 0.7 }
          titleShown={ index + 0.6 }
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
        />
      );
    case BlockTypes.SLIDES:
      return (
        <AboutUsPageSlidesBlock
          blocksShown={ index + 0.7 }
          titleShown={ index + 0.5 }
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
        />
      );
    case BlockTypes.LEASING:
      return (
        <AboutUsPageLeasingBlock
          blocksShown={ index + 0.7 }
          titleShown={ index + 0.6 }
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
        />
      );
    case BlockTypes.CARDS:
      return (
        <AboutUsPageCardsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ index + 0.7 }
          titleShown={ index + 0.5 }
        />
      );
    case BlockTypes.MANAGEMENT:
      return (
        <AboutUsPageManagementBlock
          blocksShown={ index + 0.7 }
          titleShown={ index + 0.5 }
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
        />
      );
    case BlockTypes.LOYALTY:
      return (
        <ClientsPageLoyaltyBlock
          blocksShown={ index + 0.6 }
          titleShown={ index + 0.5 }
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          onProgramSelect={ ctx.openModal }
        />
      );
    case BlockTypes.SERVICES:
      return (
        <ClientsPageServicesBlock
          blocksShown={ index + 0.7 }
          titleShown={ index + 0.6 }
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
        />
      );
    case BlockTypes.BENEFITS_TEXT:
      return (
        <ClientsEsignPageBenefitsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ index + 0.6 }
          titleShown={ index + 0.5 }
        />
      );
    case BlockTypes.PAGE_GUIDE:
      return (
        <ClientsEsignPageGuideBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ 2.7 }
          titleShown={ 2.5 }
        />
      );
    case BlockTypes.BENEFITS_CARDS:
      return (
        <OptionsPageBenefitsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          titleShown={ index + 0.5 }
          blocksShown={ index + 0.7 }
        />
      );
    case BlockTypes.FUNCTIONS:
      return (
        <OptionsPageFunctionsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          titleShown={ index + 0.5 }
          blocksShown={ index + 0.7 }
        />
      );
    case BlockTypes.COMBINATIONS:
      return (
        <OptionsPageCombinationsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          titleShown={ index + 0.5 }
          blocksShown={ index + 0.7 }
        />
      );
    case BlockTypes.MAIN:
      if ( block.content.style === "slider" ) {
        return (
          <MainPageMainBlock
            key={ block.content.key }
            onApplicationButtonPress={ () => ctx.openModal( "application" ) }
            onSlidePress={ ( idx ) => ctx.changeBackground( block.key, idx ) }
            mobile={ ctx.mobile }
            content={ content }
          />
        );
      }
      return (
        <ProgramPageMainBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          onApplicationButtonPress={ () => ctx.openModal( "application" ) }
        />
      );
    case BlockTypes.ABOUT:
      return (
        <ProgramPageAboutBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ index + 0.7 }
        />
      );
    case BlockTypes.CALCULATOR:
      return (
        <MainPageCalculatorBlock
          key={ block.content.key }
          onApplicationButtonPress={ () => {
            ctx.openModal( "application" );
            ctx.setAddCalculation && ctx.setAddCalculation( true );
          } }
          onInfoMobilePress={ ctx.openModal }
          blocksShown={ index + 0.6 }
          content={ content }
          mobile={ ctx.mobile }
        />
      );
    case BlockTypes.PROGRAMS:
      return (
        <ServicesPageProgramsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ true }
        />
      );
    case BlockTypes.OPTIONS:
      return (
        <ServicesPageOptionsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ index + 0.7 }
          onProgramSelect={ ctx.openModal }
        />
      );
    case BlockTypes.PROMOTION:
      return (
        <ServicesPagePromotionBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ index + 0.7 }
          onButtonClick={ ( idx ) => ctx.openModal( `${ block.key }_${ idx }` ) }
          onPromoSelect={ ( idx ) => ctx.changeBackground( block.key, idx ) }
        />
      );
    case BlockTypes.DOCUMENTS_FILES:
      return (
        <ClientsPageDocumentsBlock
          blocksShown={ index + 0.7 }
          titleShown={ index + 0.5 }
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
        />
      );
    case BlockTypes.DOCUMENTS_DESCRIPTION:
      if ( block.content.style === "columns" )
        return (
          <ProgramPageDocumentsBlock
            key={ block.content.key }
            content={ content }
            mobile={ ctx.mobile }
            blocksShown={ index + 0.6 }
          />
        );
      return (
        <ClientsEsignPageDocumentsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ 1.7 }
          titleShown={ 1.6 }
        />
      );
    case BlockTypes.CONDITIONS:
      return (
        <ProgramPageConditionsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ index + 0.6 }
        />
      );
    case BlockTypes.QUESTIONS:
      return (
        <ProgramPageQuestionsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          onQuestionChange={ ctx.onHeightsChanged }
          blocksShown={ index + 0.6 }
        />
      );
    case BlockTypes.DISCOUNTS:
      return (
        <ProgramPageDiscountsBlock
          key={ block.content.key }
          content={ content }
          mobile={ ctx.mobile }
          blocksShown={ index + 0.6 }
        />
      );
  }
};

export const extractBackgrounds = ( pageData: any ) => {
  const desktopbackgrounds = [ ...pageData.desktopbackgrounds ];
  const mobilebackgrounds = [ ...pageData.mobilebackgrounds ];
  for ( let i = 0; i < pageData.blocks.length; i++ ) {
    const block = pageData.blocks[ i ];
    const start = i === 0 ? -1 : i;
    const end = i + 1;
    switch ( block.type ) {
      case BlockTypes.MAIN:
        if ( block.content.style === "slider" ) {
          for ( let i = 0; i < block.content.backgroundspans.length; i++ ) {
            const span = block.content.backgroundspans[ i ];
            desktopbackgrounds.push( {
              ...span,
              key: block.key,
              srcs: block.content.slides.map(
                ( it: any ) => it.desktopbackgrounds[ i ]
              ),
            } );
            mobilebackgrounds.push( {
              ...span,
              key: block.key,
              srcs: block.content.slides.map(
                ( it: any ) => it.mobilebackgrounds[ i ]
              ),
            } );
          }
        }
        break;
      case BlockTypes.PROMOTION:
        desktopbackgrounds.push( {
          start,
          end,
          key: block.key,
          srcs: block.content.promos.map( ( it: any ) => it.imagedesktop ),
        } );
        mobilebackgrounds.push( {
          start,
          end,
          key: block.key,
          srcs: block.content.promos.map( ( it: any ) => it.imagemobile ),
          foreground: true,
        } );
        break;
    }
  }
  desktopbackgrounds.sort( ( a, b ) => a.start - b.start );
  mobilebackgrounds.sort( ( a, b ) => a.start - b.start );
  return {
    desktopbackgrounds,
    mobilebackgrounds,
  };
};

export const extractModals = ( pageData: any ) => {
  const modals = {} as any;
  for ( const block of pageData.blocks ) {
    switch ( block.type ) {
      case BlockTypes.OPTIONS:
        for ( const item of block.content.optionsdata ) {
          if ( item.ismodal ) {
            modals[ item.type ] = {
              type: ModalTypes.OPTION,
              key: item.type,
              content: item.modaldata,
            };
          }
        }
        break;
      case BlockTypes.MAIN:
        modals[ "application" ] = {
          type: ModalTypes.APPLICATION,
          key: "application",
        };
        break;
      case BlockTypes.CALCULATOR:
        modals[ "application" ] = {
          type: ModalTypes.APPLICATION,
          key: "application",
        };
        for ( const [ key, content ] of Object.entries( block.content.infoblocks ) ) {
          modals[ key ] = {
            type: ModalTypes.OPTION,
            key,
            content,
          };
        }
        break;
      case BlockTypes.PROMOTION:
        for ( let i = 0; i < block.content.promos.length; i++ ) {
          const promo = block.content.promos[ i ];
          modals[ `${ block.key }_${ i }` ] = {
            type: ModalTypes.PROMOTION,
            key: `${ block.key }_${ i }`,
            content: promo.modaldata,
          };
        }
        break;
      case BlockTypes.LOYALTY:
        for ( const item of block.content.items ) {
          modals[ item.type ] = {
            type: ModalTypes.OPTION,
            key: item.type,
            content: item.modaldata[ 0 ],
          };
        }
        break;
    }
  }
  for ( const modal of pageData.modals || [] ) {
    modals[ modal.key ] = modal;
  }
  return modals;
};
export const modalHandler = (
  { key, content, type }: any,
  ctx: {
    currentOpenModal: string | null;
    onModalClose: () => void;
    mobile?: boolean;
    addCalculation?: boolean;
  }
) => {
  let Component;
  const contentProcessed = replaceStringsInObject( _.cloneDeep( content ), ( str ) =>
    str.replaceAll( "\\n", "\n" ).replaceAll( "\\u00A0", "\u00A0" )
  );
  switch ( type ) {
    case ModalTypes.OPTION:
      Component = ctx.mobile ? ModalOptionMobile : ModalOption;
      break;
    case ModalTypes.APPLICATION:
      Component = ctx.mobile ? ModalApplicationMobile : ModalApplicationProgram;
      break;
    case ModalTypes.PROMOTION:
      Component = ctx.mobile ? ModalSpecialOrderMobile : ModalSpecialOrder;
      break;
    case ModalTypes.CONTACTS:
      Component = ctx.mobile ? ModalContactsMobile : ModalContacts;
      break;
    case ModalTypes.DETAILS:
      Component = ctx.mobile ? ModalDetailsMobile : ModalDetails;
      break;
  }
  if ( !Component ) return null;
  return (
    <Component
      content={ contentProcessed as any }
      isOpen={ ctx.currentOpenModal === key }
      onClose={ ctx.onModalClose }
      addCalculation={ ctx.addCalculation }
    />
  );
};
