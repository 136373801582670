import React, { useEffect, useState } from "react";
import { CommonBlock } from "../../../../components/commonBlock/commonBlock";
import styles from "./index.module.scss";
import { Button } from "../../../../components/button/button";
import { PageMarker } from "../../../../components/pageMarker/pageMarker";
import { DesktopBlockPropsType } from "../../../../utils/desktopBlockPropsType";
import { useIsMobile } from "../../../../utils/common";
import { useSwipeable } from "react-swipeable";

interface PromoType {
  title: string;
  description: string;
}

interface ServicesPagePromotionBlockType {
  promos: PromoType[];
  pageindicatoroverlaydesktop?: boolean;
  pageindicatoroverlaymobile?: boolean;
  promotionduration?: string;
}

export const ServicesPagePromotionBlock = (
  {
    onButtonClick,
    onPromoSelect,
    mobile,
    content,
  }: {
    onButtonClick: ( idx: number ) => void;
    onPromoSelect: ( idx: number ) => void;
  } & DesktopBlockPropsType & { content: ServicesPagePromotionBlockType } ) => {
  const PROMOTION_DURATION_MS = content.promotionduration
    ? Number( content.promotionduration ) * 1000
    : 5000;

  const [ selectedPromo, setSelectedPromo ] = useState( 0 );

  const isMobile = useIsMobile();

  const divRef: { current: HTMLDivElement | null } = { current: null };

  const [ swiped, setSwiped ] = useState( false )

  useEffect( () => {
    const scrollToSelected = () => {
      if ( !divRef.current ) return;
      const { x } = divRef.current.children[ selectedPromo ].getBoundingClientRect();
      divRef.current.scrollBy( {
        left: x,
        behavior: "smooth",
      } );
    };
    scrollToSelected();
    window.addEventListener( "resize", scrollToSelected );
    return () => {
      window.removeEventListener( "resize", scrollToSelected );
    };
  }, [ selectedPromo ] );

  const nextPromoSelect = () =>
    setSelectedPromo( ( prevValue ) =>
      prevValue >= content.promos.length - 1 ? 0 : prevValue + 1
    );

  const prevSelect = () =>
    setSelectedPromo( ( prevValue ) => prevValue === content.promos.length - 1 ? prevValue : prevValue + 1 );

  const nextSelect = () =>
    setSelectedPromo( ( prevValue ) => prevValue === 0 ? 0 : prevValue - 1 );

  useEffect( () => {
    if ( swiped ) {
      return;
    }

    const interval = setInterval( nextPromoSelect, PROMOTION_DURATION_MS );

    return () => {
      clearInterval( interval );
    };
  }, [ selectedPromo, swiped ] );

  useEffect( () => {
    if ( onPromoSelect ) {
      onPromoSelect( selectedPromo );
    }
  }, [ selectedPromo ] );

  const { ref, ...handlers } = useSwipeable( {
    onSwiped: () => setSwiped( false ),
    onSwipeStart: () => setSwiped( true ),
    onSwipedRight: () => nextSelect(),
    onSwipedLeft: () => prevSelect(),
    onSwiping: ( e ) => {
      if ( divRef.current ) divRef.current.scrollLeft += e.deltaX / -30;
    }
  } );

  return (
    <CommonBlock fullWidth>
      <div
        ref={ ( el ) => {
          divRef.current = el;
          ref( el );
        } }
        className={ styles.promosContainer }
        { ...handlers }
      >
        { content.promos.map( ( _promo, idx ) => (
          <div className={ styles.wrapper }>
            <div className={ styles.servicesSpecialOrder }>
              <p className={ styles.headingText }>{ _promo.title }</p>
              <p className={ styles.contentText }>{ _promo.description }</p>
              <Button
                onClick={ () => onButtonClick( idx ) }
                title={ "Подробнее" }
                className={ styles.button }
              />
            </div>
          </div>
        ) ) }
      </div>
      <div className={ styles.pageMarkerContainer }>
        <PageMarker
          currentPage={ selectedPromo }
          onPageSelect={ setSelectedPromo }
          pages={ content.promos.length }
          overlay={
            isMobile
              ? content.pageindicatoroverlaymobile
              : content.pageindicatoroverlaydesktop
          }
        />
      </div>
    </CommonBlock>
  );
};
